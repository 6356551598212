import { IIlionaPackagesAbbreviated } from "./../../../models/IIlionaPackage";
import { IIlionaCustomers } from "../../../models/IlionaCustomers";

export enum IlionaCustomersTypes {
    FETCH_ILIONA_CUSTOMERS_STARTED = "@@FETCH_ILIONA_CUSTOMERS_STARTED",
    FETCH_ILIONA_CUSTOMERS_SUCCESS = "@@FETCH_ILIONA_CUSTOMERS_SUCCESS",
    FETCH_ILIONA_CUSTOMERS_FAILURE = "@@FETCH_ILIONA_CUSTOMERS_FAILURE",

    FETCH_ILIONA_CUSTOMERS_PACKAGES_STARTED = "@@FETCH_ILIONA_CUSTOMERS_PACKAGES_STARTED",
    FETCH_ILIONA_CUSTOMERS_PACKAGES_SUCCESS = "@@FETCH_ILIONA_CUSTOMERS_PACKAGES_SUCCESS",
    FETCH_ILIONA_CUSTOMERS_PACKAGES_FAILURE = "@@FETCH_ILIONA_CUSTOMERS_PACKAGES_FAILURE",

    FETCH_ADMIN_CHECK_STARTED = "@@FETCH_ADMIN_CHECK_STARTED",
    FETCH_ADMIN_CHECK_SUCCESS = "@@FETCH_ADMIN_CHECK_SUCCESS",
    FETCH_ADMIN_CHECK_FAILURE = "@@FETCH_ADMIN_CHECK_FAILURE",

    FETCH_CUSTOMER_SHORTHAND_STARTED = "@@FETCH_CUSTOMER_SHORTHAND_STARTED",
    FETCH_CUSTOMER_SHORTHAND_SUCCESS = "@@FETCH_CUSTOMER_SHORTHAND_SUCCESS",
    FETCH_CUSTOMER_SHORTHAND_FAILURE = "@@FETCH_CUSTOMER_SHORTHAND_FAILURE",

    FETCH_STORE_AGENT_VERSION_STARTED = "@@STORE_AGENT_VERSION_STARTED",
    FETCH_STORE_AGENT_VERSION_FAILURE = "@@STORE_AGENT_VERSION_FAILURE",
    FETCH_STORE_AGENT_VERSION_SUCCESS = "@@STORE_AGENT_VERSION_SUCCESS",

}

export type CustomersState = Readonly<{
    customers: IIlionaCustomers[];
    errorMessage: string;
    isFetching: boolean;
    customerPackages: IIlionaPackagesAbbreviated[];
    packagesErrorMessage: string;
    packagesIsFetching: boolean;
    isAdmin: boolean;
    customerName: string;
    storeVersion: number;
}>;
