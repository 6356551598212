import React, {useState} from 'react';
import NL from 'country-flag-icons/react/3x2/NL';
import GB from 'country-flag-icons/react/3x2/GB';
import DE from 'country-flag-icons/react/3x2/DE';
import styled from 'styled-components';

const LanguageContainer = styled.div`
  align-self: center;
  justify-self: center;
`;

const FlagIcon = styled.div`
    width: 20px;     
    height: 20px;
    margin-right: 10px;
    

    svg {
        width: 120%;
        height: 120%;
    }
`;

const FlagText = styled.span`
    vertical-align: middle;
`;

const DropdownOption = styled.div`
    display: flex;
    cursor: pointer;
    width: 150px;
  
`;

const DropdownOptions = styled.div`
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    padding: 6px;
`;


const LANGUAGES: { [key: string]: string } = {
    nl: "Nederlands",
    en: "English",
    de: "Deutsch"
};

interface LanguageSelectorProps {
    currentLanguage: string;
    onLanguageChange: (newLang: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ currentLanguage, onLanguageChange }) => {
    const otherLanguages = Object.keys(LANGUAGES).filter(lang => lang !== currentLanguage) as (keyof typeof LANGUAGES)[];

    const [isOpen, setIsOpen] = useState(false);
    const FLAG_COMPONENTS: { [key: string]: React.FC } = {
        nl: NL,
        en: GB,
        de: DE
    };

    const CurrentFlag = FLAG_COMPONENTS[currentLanguage];

    return (
        <LanguageContainer onClick={() => setIsOpen(!isOpen)}>
            <DropdownOption>
                <FlagIcon><CurrentFlag /></FlagIcon>
                <FlagText>{LANGUAGES[currentLanguage]}</FlagText>
            </DropdownOption>
            {isOpen && (
                <DropdownOptions>
                    {otherLanguages.map(lang => {
                        const Flag = FLAG_COMPONENTS[lang];
                        return (
                            <DropdownOption key={lang} onClick={() => onLanguageChange(lang as string)}>
                                <FlagIcon><Flag /></FlagIcon>
                                {LANGUAGES[lang]}
                            </DropdownOption>
                        );
                    })}
                </DropdownOptions>
            )}
        </LanguageContainer>
    );
};

export default LanguageSelector;
