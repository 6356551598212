import messages_en from "./en";
import messages_nl from "./nl";
import messages_de from "./de";


export const translationSets = {
    nl: messages_nl,
    en: messages_en,
    de: messages_de,
};
