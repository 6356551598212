import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";

export default function useMsalToken() {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState("");

    useEffect(() => {
        const account = instance.getAccountByHomeId(accounts[0]?.homeAccountId);

        if (account) {
            if (!token) {
                instance.acquireTokenSilent({
                    account: account,
                    scopes: ["api://2f189308-3bed-45d4-b52e-05c11f74e6ea/User.Read"]
                }).then(response => {
                    setToken(response.accessToken);
                }).catch(e => {
                    console.error(e);
                });
            }
        }
    }, [instance, accounts, token]);

    return token;
}