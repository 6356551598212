export enum ErrorMessagesEnum {
    duplicatePackage = "duplicate entry",
    noCSAClientFound = "Computer name not found",
    storeVersionError = "Error store",
    noStoreAgentConnection = "Could not connect with store agent",
    noSubscriptionKey = "Subscription key not found",
    noLocalPackagesFound = "No local packages found",
    failedInstallingPackage = "Installation was not successful",
    addPackageExist = "Package already exists"
}
