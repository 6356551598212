import {useState, useEffect} from "react";
import callMsGraphBlob from "../../utils/callMsGraphBlob";
import {graphConfig, loginRequest} from "../../config/authConfig";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import { Link } from "react-router-dom";

// material components
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LogoutIcon from '@mui/icons-material/Logout';

export const ProfileMenu = () => {

    // const [userProfilePhoto, setUserProfilePhoto] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    // useEffect(() => {
    //     if (isAuthenticated && accounts.length > 0) {
    //         const request = {
    //             ...loginRequest,
    //             account: accounts[0]
    //         };
    //
    //         instance.acquireTokenSilent(request).then(response => {
    //             callMsGraphBlob(graphConfig.graphProfileEndpoint, response.accessToken).then(data => {
    //                 const url = URL.createObjectURL(data);
    //                 setUserProfilePhoto(url);
    //             }).catch(error => {
    //                 console.error("Error fetching user profile photo:", error);
    //             });
    //         }).catch(error => {
    //             console.error("Error acquiring access token:", error);
    //         });
    //     }
    // }, [isAuthenticated, accounts, instance]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        })
    };

    return (
        <>
            <Box>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="false"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar alt="profile-picture"/>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/*TODO Create profile page*/}
                {/*<MenuItem onClick={handleClose}>*/}
                {/*    <Link to="/profile" style={{ textDecoration: 'none' }}>*/}
                {/*             <p style={{ fontSize: 'medium', color: 'black' }}>Profile</p>*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}
                {/*<Divider />*/}

                <MenuItem onClick={handleLogout}>
                    <p style={{ fontSize: 'medium', color: 'black', marginRight: '15px' }}>Logout</p>
                    <ListItemIcon>
                        <LogoutIcon fontSize="large" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </>
    )
}