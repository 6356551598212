import { IIlionaPackagesAbbreviated } from "../models/IIlionaPackage";
import { IIlionaCategory } from "../models/Ilionacategory";

export function filterPackagesPerCategory(categories: IIlionaCategory[], packages: IIlionaPackagesAbbreviated[]) {
    const overigRowKey = categories.find(cat => cat.RouteFriendlyName === "Overig")?.RowKey;

    if (!overigRowKey) {
        throw new Error("Overig category not found.");
    }

    const appsPerCategory = categories.map((cat: IIlionaCategory) => {
        const packagesThatUseCurrentCategoryId = packages.filter((p: IIlionaPackagesAbbreviated) => {
            if (p.category !== cat.RowKey && cat.RouteFriendlyName !== "Overig") {
                p.category = overigRowKey;
            }
            return p.category === cat.RowKey;
        });

        return { name: cat.RouteFriendlyName, packages: packagesThatUseCurrentCategoryId };
    });

    return appsPerCategory;
}