import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {screenSize} from "../../themes/global"
import LanguageSelector from "../languageSelector/LanguageSelector";
import {NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {IReduxApplicationState} from "../../models/redux/IReduxApplicationState";
import {ProfileMenu} from "../profile/ProfileMenu";
import useMsalToken from "../../hooks/useMsalToken";

const TopbarSection = styled.div`
  width: 100%;
  height: auto;
  justify-content: space-between;
  grid-column: 1 / 13;
  display: grid;
  grid-template-columns: 6.4rem repeat(10, 1fr) 6.4rem;
  border-bottom: 1px solid ${(p) => p.theme.borderNeutral};
  background: white;

`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / 3;
  margin-left: 1.6rem;
  width: 100%;
  max-height: 65px;

  @media ${screenSize.tablet} {
    grid-column: 2 / 3;
    margin-left: 0;
  }
`;

const LoggedInContainer = styled.div`
  display: flex;
  align-self: center;
  justify-self: end;
  grid-column: 11 / 13;
  margin-right: 1.6rem;
`;

const PageNavBar = styled.div`
  display: flex;
  align-self: center;
  grid-column: 3 / 11;

  .link {
    margin: 0.7rem;
  }

  li {
    display: inline-flex;
    list-style-type: none;
    height: 100%;
    align-items: center;
    font-size: 1.4rem;

    & a {
      color: ${(p) => p.theme.primaryNavigationTextColor};
      padding: 0 8px;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @media ${screenSize.tablet} {
        padding: 0 16px;
      }
    }

    .active {
      font-weight: bold;
      color: #F7F7F7;
      background-color: #E8003D;
      border-radius: 5px;
    }

`;

const ProfileContainer = styled.div`
  display: flex;
  align-self: center;
  justify-self: end;
  grid-column: 11 / 13;
  margin-right: 1.6rem;
`;


interface HeaderBarProps {
    onLocaleChange: (locale: string) => void;
}

export const HeaderBar: React.FC<HeaderBarProps> = ({onLocaleChange}) => {

    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);

    const [language, setLanguage] = useState(localStorage.language || "nl");
    const [isBitlocker, setIsBitlocker] = useState(false);
    const [isLeap, setIsLeap] = useState(false);
    const [leapAccess, setLeapAccess] = useState(false)

    const token: any = useMsalToken()

    const getBitlockerStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/is-bitlocker`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': subscriptionKey,
                    'Authorization': `Bearer ${token}`
                },
            });

            const data = await response.json();

            setIsBitlocker(data.data);
        } catch (error) {
            console.error('Failed to fetch Bitlocker status', error);
        }
    };

    const getSupportStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/is-leap`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': subscriptionKey,
                    'Authorization': `Bearer ${token}`
                },
            });

            const data = await response.json();

            setIsLeap(data.data);
        } catch (error) {
            console.error('Failed to fetch leap status', error);
        }
    };

    const userAccessLeap = async () => {
        if (subscriptionKey && token != "" ) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/leap_access`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': subscriptionKey,
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setLeapAccess(data.data);
            } catch (error) {
                console.log("Something went wrong, try again");
            }
        }
    }

    // function fetchAuthenticatedData() {
    //     fetch(`${process.env.REACT_APP_API_URL}/token/verify`, {
    //         method: "GET",
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setIsVerified(data);
    //         })
    //         .catch((e) => {
    //             console.error(e);
    //             setIsVerified(false);
    //         });
    // }

    useEffect(() => {
        if (subscriptionKey != "") {
            getBitlockerStatus();
            getSupportStatus();
            userAccessLeap()
            // fetchAuthenticatedData()
        }
    }, [subscriptionKey, token]);

    const handleLanguageChange = (newLang: string) => {
        setLanguage(newLang);
        localStorage.setItem("language", newLang)

        onLocaleChange(newLang);
    };


    return (
        <TopbarSection>
            <Brand>
                <img src="/assets/img/ilionx_logo_new.jpg" alt="ilionx logo"
                     style={{maxHeight: "100%", maxWidth: 150}}/>
            </Brand>

            <PageNavBar>
                <nav>
                    {/*<li>*/}
                    {/*    <NavLink to="/" className={({ isActive }) => (isActive ? "active link" : "link")}>*/}
                    {/*        <FormattedMessage*/}
                    {/*            id="navigation.page.home"*/}
                    {/*            defaultMessage="Home"*/}
                    {/*        ></FormattedMessage>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li>
                        <NavLink className={({isActive}) => (isActive ? "active link" : "link")} to="/appstore">
                            <FormattedMessage
                                id="navigation.page.appstore"
                                defaultMessage="appwinkel"
                            ></FormattedMessage>
                        </NavLink>
                    </li>

                    {isBitlocker && (
                        <li>
                            <NavLink className={({isActive}) => (isActive ? "active link" : "link")} to="/bitlocker">
                                <FormattedMessage
                                    id="navigation.page.bitlocker"
                                    defaultMessage="bitlocker"
                                ></FormattedMessage>
                            </NavLink>
                        </li>
                    )}

                    { isLeap && leapAccess && (
                        <li>
                            <NavLink to="/support/request-admin" className={({ isActive }) => (isActive ? "active link" : "link")}>
                                <FormattedMessage id="navigation.support.request-admin" defaultMessage="Local admin" />
                            </NavLink>
                        </li>
                    )}

                </nav>
            </PageNavBar>

            <LoggedInContainer role="region">
                <LanguageSelector
                    currentLanguage={language}
                    onLanguageChange={handleLanguageChange}
                />

                <ProfileMenu/>
            </LoggedInContainer>
        </TopbarSection>
    );
};