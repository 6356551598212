import {useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import useMsalToken from "../hooks/useMsalToken";


const Landing = () => {
    // const { instance, accounts } = useMsal();

    // const AccessToken: any = useMsalToken()

    Navigate({to:"/appstore"});

    return (
        <div>
            <h1>Landing page</h1>
        </div>
    )
}

export default Landing