import React, {useEffect, useState} from "react";
import {Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {ErrorBoundary} from "react-error-boundary";
// redux
import {IReduxApplicationState} from "../models/redux/IReduxApplicationState";
import {fetchIlionaCategories} from "../store/slices/categories/categoryActions";
// components
import Nav from "../components/nav/Nav";
import ErrorFallback from "../components/errorComponent/ErrorFallback";
// page
import CategoryPackages from "./CategoryPackages";
import PackageDetail from "./PackageDetail";
import UninstallPackage from "./UninstallPackage";
import AddPackage from "./admin/AddPackage";
import InstallPackageThirdParty from "./admin/InstallPackageThirdParty";
import AdminHome from "./admin/AdminHome";
import NotAllowed from "./NotAllowed";
import Home from "./Home";
// util
import AdminRoutes from "../utils/AdminRoutes";
import {Spinner} from "../components/spinner/Spinner";
import {fetchComputerName, fetchLocalPackages} from "../store/slices/packages/packagesActions";
import {ErrorMessagesEnum} from "../models/errorsEnum";
import {fetchIlionaAdminCheck, fetchStoreVersion} from "../store/slices/customers/customerActions";
import ErrorFailedConnectingStoreAgent from "../components/errorComponent/ErrorFailedConnectingStoreAgent";
import useMsalToken from "../hooks/useMsalToken";

const MainWrapper = styled.div`
  width: 100%;
  grid-row: 5 / 6;
  grid-column: 1 / 13;
  display: grid;
  grid-template-columns: 6.4rem repeat(10, 1fr) 6.4rem;
`;

function Appstore() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const categories = useSelector((state: IReduxApplicationState) => state.categorySlice);
    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const computerName = useSelector((state: IReduxApplicationState) => state.packagesSlice.computerName);
    const packages = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    const storeVersion = useSelector((state: IReduxApplicationState) => state.customersSlice.storeVersion);

    const [storeInitialLoading, SetStoreInitialLoading] = useState(true);

    const token: any = useMsalToken()

    const errorAgent = useSelector(
        (state: IReduxApplicationState) => state.customersSlice.errorMessage
    );

    const getStoreData = async () => {
        if (subscriptionKey != "" && categories.categories.length <= 0) {
            dispatch(fetchIlionaCategories(subscriptionKey, token));
            SetStoreInitialLoading(false);
        }

    }

    const getInitialData = async () => {

        if (subscriptionKey != "") {
            dispatch(fetchComputerName(storeVersion, token));
        }
    };

    useEffect(() => {
        getInitialData();
    }, [subscriptionKey]);


    useEffect(() => {
        getStoreData();
    }, [subscriptionKey]);

    // Fetch local packages
    useEffect(() => {
        if (computerName && subscriptionKey != "" && packages.ilionaPackages.length <= 0) {
            dispatch(fetchLocalPackages(computerName, subscriptionKey, storeVersion, token));
        }
    }, [computerName, subscriptionKey]);

    useEffect(() => {
        // make sure the current path isn't already '/appstore/notallowed' to avoid repeated navigation.
        if (errorAgent === "Something went wrong" && location.pathname !== '/appstore/notallowed') {
            navigate("/appstore/notallowed", {replace: true});
        }
    }, [errorAgent, storeInitialLoading, location.pathname]);


    useEffect(() => {
        if (subscriptionKey != "") {
            dispatch(fetchIlionaAdminCheck(subscriptionKey, token))
        }
    }, [subscriptionKey]);


    return (
        <MainWrapper>
            {storeInitialLoading && (
                <div style={{display: "none"}}>
                    <Spinner/>
                </div>
            )}

            {errorAgent === ErrorMessagesEnum.noStoreAgentConnection ? (
                <ErrorFailedConnectingStoreAgent errorMsg={errorAgent}/>
            ) : (
                <>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <Routes>
                            <Route path="categorie/:catName" element={<CategoryPackages/>}/>
                            <Route path="details/:rowkey" element={<PackageDetail/>}/>
                            <Route path="uninstallpackages" element={<UninstallPackage/>}/>
                            <Route path="admin" element={<AdminRoutes/>}>
                                <Route path="home" element={<AdminHome/>}/>
                                {/*<Route path="package/:rowkey" element={<EditPackage />} />*/}
                                <Route path="packages/add" element={<AddPackage/>}/>
                                <Route path="installpackage" element={<InstallPackageThirdParty/>}/>
                            </Route>
                            <Route path="notAllowed" element={<NotAllowed/>}/>
                            <Route path="" element={<Home/>}/>
                        </Routes>

                    </ErrorBoundary>

                    <Nav categories={categories.categories} subKey={subscriptionKey}/>

                    <Outlet/>
                </>
            )}
        </MainWrapper>

    );
}

export default (Appstore);