import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { screenSize } from "../../themes/global";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { IIlionaCategory } from "../../models/Ilionacategory";
import { translateRoutePaths } from "../../i18n/CategoryTranslations";
import { useDispatch, useSelector } from "react-redux";
import { IReduxApplicationState } from "../../models/redux/IReduxApplicationState";
import { fetchIlionaAdminCheck } from "../../store/slices/customers/customerActions";

const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  
  .non-admin-ul {
    flex: 1;
  }

  .admin-ul {   
    flex: 0;
  }

    ul {
        width: 100%;
        height: 100%;
        margin-left: 0;

        .hide-small-screen {
            display: none;

            @media ${screenSize.normalSizeTable} {
                display: inline-block;
            }
        }
      
      .hide-big-screen {
        display: inline-block;
        
        @media ${screenSize.desktop} {
          display: none;
        }
      }

        .admin-portal {
            width: 100%;
            display: inline-flex;
            justify-content: flex-end;
        }

        li {
            display: inline-flex;
            list-style-type: none;
            height: 100%;
            align-items: center;
            font-size: 1.4rem;

            & a {
                color: ${(p) => p.theme.primaryNavigationTextColor};
                padding: 0 8px;
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                @media ${screenSize.tablet} {
                    padding: 0 16px;
                }
            }

            .active { 
                //color: ${(p) => p.theme.primaryNavigationTextColor};
              //background-color: #eee;
              font-weight: bold;
              color: #F7F7F7;
              background-color: #E8003D;
              
            }
        }
    }

  .disabled-link {
    pointer-events: none; /* Prevent clicking */
    color: #ccc; /* Grayed out to indicate it's disabled */
    cursor: not-allowed; /* Change cursor to indicate the link is not actionable */
  }
`;

interface NavProps {
    categories: IIlionaCategory[];
    subKey: string;
}

const NavItems = ({ intl, categories, subKey }: NavProps & WrappedComponentProps) => {
    const customers = useSelector((state: IReduxApplicationState) => state.customersSlice);
    const dispatch = useDispatch();
    let categoryLinks;

    const packages = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    // Check if there are package
    const hasPackages = packages.ilionaPackages.length <= 0;

    if (categories && categories.length > 0) {
        categoryLinks = categories.map((category: IIlionaCategory) => {
            return (
                <li className="" key={category.Name}>
                    <NavLink
                        to={`/appstore/categorie/${category.RouteFriendlyName.toLowerCase()}`}
                        data-testid="dynamicLink"
                        className={({ isActive }) => (isActive ? "active" : "")}
                    >
                        {translateRoutePaths(category.Name, intl)}
                    </NavLink>
                </li>
            );
        });
    }

    let adminLinks;
    if (customers?.isAdmin) {
        adminLinks =  
            <ul className="admin-ul" >
                <li className="admin-portal">
                    <NavLink to="/appstore/admin/home"
                             className={({ isActive }) => (isActive ? "active" : "")}
                    >
                        <FormattedMessage
                            id="navigation.admin.panel"
                            defaultMessage="Admin portaal"
                        ></FormattedMessage>
                    </NavLink>
                </li>
            </ul>
    }

    return (
        <>
            <NavItemsContainer>
                <ul data-testid="links" className="non-admin-ul">
                    <li key="home">
                        <NavLink to="/appstore" end data-testid="homeLink" className={({ isActive }) => (isActive ? "active" : "")}>
                            <FormattedMessage
                                id="navigation.home.text"
                                defaultMessage="Alle applicaties"
                            ></FormattedMessage>
                        </NavLink>
                    </li>

                    {categoryLinks}

                    <li key="uninstall">
                        <NavLink to="/appstore/uninstallpackages" data-testid="uninstallLink" className={({ isActive }) => (isActive ? "active" : "")}>
                            <FormattedMessage
                                id="navigation.uninstall.text"
                                defaultMessage="Applicaties deinstalleren"
                            ></FormattedMessage>
                        </NavLink>
                    </li>
                </ul>

                {adminLinks}

            </NavItemsContainer>
        </>
    );
};

export default injectIntl(NavItems);
