import {Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import {RequestAdmin} from "../components/support/RequestAdmin";
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100%;
  grid-row: 5 / 6;
  grid-column: 1 / 13;
  display: flex; 
  justify-content: center;
  align-items: start; 
  padding: 0 6.4rem; 
`;

export const Support = () => {

    return (
        <MainWrapper>
            <>
                <Routes>
                    <Route path="/request-admin" element={<RequestAdmin/>}/>
                </Routes>

                <Outlet/>
            </>
        </MainWrapper>
    )
}