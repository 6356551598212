import { IIlionaCustomers } from "./../../../models/IlionaCustomers";
import { CustomersState, IlionaCustomersTypes } from "./customerTypes";
import { IIlionaCategory } from "../../../models/Ilionacategory";

const initialState: CustomersState = {
    errorMessage: "",
    customers: [] as IIlionaCustomers[],
    isFetching: false,
    customerPackages: [],
    packagesErrorMessage: "",
    packagesIsFetching: false,
    isAdmin: false,
    customerName: "",
    storeVersion: 0,
};

export function CustomersReducer(
    state = initialState,
    action: { type: IlionaCustomersTypes; payload: any }
): CustomersState {
    switch (action.type) {
        case IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_STARTED:
            return {
                ...state,
                isFetching: true,
            };

        case IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_FAILURE:
            return {
                ...state,
                errorMessage: action?.payload?.errorMessage,
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload.customers.data,
                errorMessage: "",
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_STARTED:
            return {
                ...state,
                isFetching: true,
            };

        case IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_FAILURE:
            return {
                ...state,
                packagesErrorMessage: action?.payload?.errorMessage,
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_SUCCESS:
            return {
                ...state,
                customerPackages: action.payload.customerPackages.data,
                packagesErrorMessage: "",
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_ADMIN_CHECK_STARTED:
            return {
                ...state,
                isFetching: true,
            };

        case IlionaCustomersTypes.FETCH_ADMIN_CHECK_FAILURE:
            return {
                ...state,
                errorMessage: action?.payload?.errorMessage,
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_ADMIN_CHECK_SUCCESS:
            return {
                ...state,
                isAdmin: action.payload.data,
                errorMessage: "",
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_STARTED:
            return {
                ...state,
                isFetching: true,
            };

        case IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_FAILURE:
            return {
                ...state,
                errorMessage: action?.payload?.errorMessage,
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_SUCCESS:
            return {
                ...state,
                customerName: action.payload.data.data,
                errorMessage: "",
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_STARTED:
            return {
                ...state,
                isFetching: true,
            };

        case IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_FAILURE:
            return {
                ...state,
                errorMessage: action?.payload?.errorMessage,
                isFetching: false,
            };

        case IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_SUCCESS:
            return {
                ...state,
                storeVersion: action.payload.data,
                errorMessage: "",
                isFetching: false,
            };

        default: {
            return state;
        }
    }
}