import { ErrorMessagesEnum } from "./../../../models/errorsEnum";
import { IIlionaLocalPackage } from "./../../../models/ilionaLocalPackage";
import { fetchIlionaPackageDetails } from "./packagesActions";
import { IIlionaPackagesAbbreviated } from "./../../../models/IIlionaPackage";
/* eslint-disable indent */
import { IlionaPackagesTypes, PackagesState } from "./packageTypes";
import { IIlionaPackages } from "../../../models/IIlionaPackage";

const initialState: PackagesState = {
    errorMessage: "",
    ilionaPackages: [] as IIlionaPackagesAbbreviated[],
    selectedPackageDetail: [] as IIlionaPackages[],
    isFetching: false,
    packageInstallFailed: false,
    packageInstallSuccessful: false,
    packageInstallSuccessfulSync: false,
    computerName: "",
    subscriptionKey: "",
    locallyInstalledPackages: [] as IIlionaLocalPackage[],
    computerNameError: "",
    packageAdded: false,
    packageUninstallSuccessful: false,
    packageUninstallFailed: false,
    showOverlay: false
};

export function PackagesReducer(
    state = initialState,
    action: { type: IlionaPackagesTypes; payload: any }
): PackagesState {
    switch (action.type) {
        case IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
                packageInstallSuccessfulSync: false
            };

        case IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_FAILURE:
            return {
                ...state,
                errorMessage: action?.payload?.errorMessage,
                isFetching: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_SUCCESS:
            return {
                ...state,
                ilionaPackages: action.payload.packages.data,
                errorMessage: "",
                isFetching: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_PACKAGE_DETAIL_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_PACKAGE_DETAIL_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_PACKAGE_DETAIL_SUCCESS:
            return {
                ...state,
                selectedPackageDetail: action.payload.packageDetails.data,
                errorMessage: "",
                isFetching: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false,
                packageInstallFailed: true,
                packageInstallSuccessful: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                errorMessage: "",
                packageInstallSuccessful: action.payload.installed,
                packageAdded: false,
            };

        case IlionaPackagesTypes.CLOSE_TOAST_MESSAGE:
            return {
                ...state,
                packageInstallSuccessful: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.REMOVE_PACKAGE_ERROR:
            return {
                ...state,
                errorMessage: "",
                packageInstallFailed: false,
                packageInstallSuccessful: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_SUCCESS:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                packageInstallSuccessful: false,
                computerNameError: "",
                computerName: action.payload.computer,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_FAILURE:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                computerNameError: ErrorMessagesEnum.noCSAClientFound,
                packageInstallSuccessful: false,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                packageInstallSuccessful: false,
                subscriptionKey: action.payload?.subscriptionKey,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_FAILURE:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                errorMessage: action.payload.errorMessage,
                packageInstallSuccessful: false,
                subscriptionKey: "",
            };

        case IlionaPackagesTypes.FETCH_ILIONA_LOCAL_PACKAGES_STARTED:
            return {
                ...state,
                isFetching: true,
            };

        case IlionaPackagesTypes.FETCH_ILIONA_LOCAL_PACKAGES_FAILURE:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                errorMessage: action.payload.errorMessage,
                subscriptionKey: "",
            };

        case IlionaPackagesTypes.FETCH_ILIONA_LOCAL_PACKAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                locallyInstalledPackages: action.payload?.packages?.local_packages,
            };

        case IlionaPackagesTypes.ILIONA_ADD_PACKAGE_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
            };

        case IlionaPackagesTypes.ILIONA_ADD_PACKAGE_FAILURE:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                errorMessage: action.payload.errorMessage,
                packageInstallSuccessful: false,
                subscriptionKey: "",
                packageAdded: false,
            };

        case IlionaPackagesTypes.ILIONA_ADD_PACKAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                packageInstallSuccessful: false,
                ilionaPackages: action.payload?.packages,
                packageAdded: true,
            };

        case IlionaPackagesTypes.REMOVE_UNINSTALL_ALERT:
            return {
                ...state,
                packageUninstallFailed: false,
                packageUninstallSuccessful: false,
                packageInstallSuccessfulSync: false
            };

        case IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
                showOverlay: true
            };

        case IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_FAILURE:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                errorMessage: "",
                packageUninstallFailed: true,
                packageUninstallSuccessful: false,
                packageInstallSuccessful: false,
                packageAdded: false,
                showOverlay: false
            };

        case IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_SUCCESS:

            const isUninstalled =
                action?.payload?.packageDetails?.data === "package has been unintalled" ? true : false;

            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                packageInstallSuccessful: false,
                packageUninstallFailed: false,
                packageUninstallSuccessful: isUninstalled,
                packageAdded: true,
                showOverlay: false
            };


        case IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_STARTED:
            return {
                ...state,
                isFetching: true,
                packageAdded: false,
                showOverlay: true
            };

        case IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false,
                packageInstallFailed: true,
                packageInstallSuccessfulSync: false,
                packageAdded: false,
                showOverlay: false
            };

        case IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_SUCCESS:
            return {
                ...state,
                isFetching: false,
                packageInstallFailed: false,
                errorMessage: "",
                packageInstallSuccessfulSync: true,
                packageAdded: false,
                showOverlay: false
            };

        default: {
            return state;
        }
    }
}
