import {useSelector} from "react-redux";
import {IReduxApplicationState} from "../models/redux/IReduxApplicationState";
import {Navigate, Outlet, Route} from "react-router-dom";


const AdminRoutes = () => {

    const customer = useSelector((state: IReduxApplicationState) => state.customersSlice);

    return(
        customer.isAdmin ? <Outlet/> : <Navigate to={"/appstore/notallowed"}/>
    )
}

export default AdminRoutes