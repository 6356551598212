import { ErrorMessagesEnum } from "./../../../models/errorsEnum";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IlionaPackagesTypes, PackagesState } from "./packageTypes";
import {
    RequestFailedDispatchType,
    RequestStartedDispatchType,
    RequestSuccessDispatchType,
} from "../../../models/redux/IReduxActionTypes";
import { IAddPackage } from "../../../models/IAddPackage";
import { IlionaInstallPackage } from "../../../models/ilionaInstallPackage";

export const fetchIlionaPackages = (subscriptionKey: string, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaPackagesRequest(subscriptionKey, token));
    };
};

// GET all packages with abbreviated
const fetchIlionaPackagesRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    subscriptionKey, token
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(`${process.env.REACT_APP_API_URL}/list`, {
                headers: requestHeaders,
            });

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }

            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_SUCCESS,
                payload: { packages: result },
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};

// GET the details for 1 package
export const fetchIlionaPackageDetails = (id: string, subscriptionKey: string, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaPackageDetailsRequest(id, subscriptionKey, token));
    };
};

const fetchIlionaPackageDetailsRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    id: string,
    subscriptionKey: string,
    token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGE_DETAIL_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(`${process.env.REACT_APP_API_URL}/get_by_id/${id}`, {
                headers: requestHeaders,
            });

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { packageDetails: result },
                type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGE_DETAIL_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGE_DETAIL_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};

// GET Install a package
export const InstallPackage = (
    displayName: string,
    packageName: string,
    computerName: string,
    customer_shorthand: string,
    isSilentInstall: boolean,
    subscriptionKey: string,
    endpoint: string,
    token: string
) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(
            fetchInstallPackageRequest(
                displayName,
                packageName,
                computerName,
                customer_shorthand,
                isSilentInstall,
                subscriptionKey,
                endpoint,
                token
            )
        );
    };
};

export function closeSuccessInstalledMessage() {
    return { type: IlionaPackagesTypes.CLOSE_TOAST_MESSAGE };
}

export function removePackageError() {
    return { type: IlionaPackagesTypes.REMOVE_PACKAGE_ERROR };
}

export function removeUninstallAlert() {
    return { type: IlionaPackagesTypes.REMOVE_UNINSTALL_ALERT };
}

const fetchInstallPackageRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    displayName: string,
    packageName: string,
    computerName: string,
    customer_shorthand: string,
    isSilentInstall: boolean,
    subscriptionKey: string,
    endpoint: string,
    token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_STARTED,
        };


        dispatch(requestStartedAction);
        let body = JSON.stringify({
            displayName,
            packageName: packageName,
            clientIdentification: computerName,
            isSilentInstall: isSilentInstall,
            subscriptionKey: subscriptionKey,
        });

        if (customer_shorthand) {
            body = JSON.stringify({
                displayName,
                packageName: packageName,
                clientIdentification: computerName,
                customerShorthand: customer_shorthand,
                isSilentInstall: isSilentInstall,
                subscriptionKey: subscriptionKey,
            });
        }

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
                method: "POST",
                headers: requestHeaders,
                body,
            });

            if (response.status === 422) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_FAILURE,
                    payload: { errorMessage: ErrorMessagesEnum.duplicatePackage },
                };
                return dispatch(requestFailedAction);
            }

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { installed: true },
                type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};


// GET Install multiple packages
export const InstallMultiplePackage = (
    packagesObject: IlionaInstallPackage, token: string
) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(
            fetchInstallMultiplePackagesRequest(
                packagesObject, token
            )
        );
    };
};


const fetchInstallMultiplePackagesRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    packageObject: IlionaInstallPackage, token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_STARTED,
        };
        
        dispatch(requestStartedAction);

        let requestBody = Object.assign({}, packageObject);
        delete requestBody?.subscriptionKey;
        const body = JSON.stringify(requestBody);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", packageObject?.subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(`${process.env.REACT_APP_API_URL}/install-multiple-packages`, {
                method: "POST",
                headers: requestHeaders,
                body
            });

            if (response.status === 422) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_PACKAGES_FAILURE,
                    payload: { errorMessage: ErrorMessagesEnum.duplicatePackage },
                };
                return dispatch(requestFailedAction);
            }

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { installed: true },
                type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};


// GET Conmputer name
export const fetchComputerName = (storeVersion: number, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaComputerNameRequest(storeVersion, token));
    };
};

const fetchIlionaComputerNameRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (storeVersion, token) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set('Authorization', `Bearer ${token}`)

            let apiUrl: string

            if(storeVersion === 2) {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/computer`
            } else {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v1/computer`
            }

            const response: Response = await fetch(
                apiUrl,
                {
                    headers: requestHeaders,
                }
            );

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_FAILURE,
                    payload: { errorMessage: ErrorMessagesEnum.noCSAClientFound },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { computer: result.computer_name },
                type: IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_COMPUTER_NAME_FAILURE,
                payload: { errorMessage: ErrorMessagesEnum.noCSAClientFound },
            };
            dispatch(requestFailedAction);
        }
    };
};

// GET Conmputer name
export const fetchSubscriptionKey = (storeVersion: number, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaSubscriptionKeyRequest(storeVersion, token));
    };
};

const fetchIlionaSubscriptionKeyRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (storeVersion: number, token: string) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set('Authorization', `Bearer ${token}`)

            let apiUrl: string

            if(storeVersion === 2) {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/subscriptionkey`
            } else {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v1/subscriptionkey`
            }

            const response: Response = await fetch(
                apiUrl,
                {
                    headers: requestHeaders,
                }
            );

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { subscriptionKey: result.subscription_key },
                type: IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_SUBSCRIPTION_KEY_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};

// GET Local packages
export const fetchLocalPackages = (computerName: string, subscriptionKey: string, storeVersion: number, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaLocalPackages(computerName, subscriptionKey, storeVersion, token));
    };
};

const fetchIlionaLocalPackages: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    computerName: string,
    subscriptionKey: string,
    storeVersion: number,
    token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.FETCH_ILIONA_LOCAL_PACKAGES_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)

            let apiUrl: string

            if(storeVersion === 2) {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/packagemanagement/localpackages`
            } else {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v1/localpackages`
            }

            const response: Response = await fetch(
                apiUrl,
                {
                    headers: requestHeaders,
                }
            );

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.FETCH_ILIONA_LOCAL_PACKAGES_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            let packageList =result

            if (storeVersion === 2) {
                packageList = {
                    "local_packages": result
                }
            }

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { packages: packageList },
                type: IlionaPackagesTypes.FETCH_ILIONA_LOCAL_PACKAGES_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.FETCH_ILIONA_INSTALL_PACKAGE_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};

// POST add a package to the appstore
export const AddPackageRedux = (packageData: IAddPackage, image: any, subscriptionkey: string, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(AddPackageRequest(packageData, image, subscriptionkey, token));
    };
};

const AddPackageRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    packageData: IAddPackage,
    image: any,
    subscriptionKey: string,
    token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.ILIONA_ADD_PACKAGE_STARTED,
        };
        dispatch(requestStartedAction);

        const formData = new FormData();
        formData.append("incoming_file", image);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(`${process.env.REACT_APP_API_URL}/files`, {
                method: "POST",
                headers: requestHeaders,
                body: formData,
            });

            if (response.status === 200 || response.status === 201 || response.status === 204) {
                const body = JSON.stringify(packageData);

                const requestHeadersAddPackage: any = new Headers();
                requestHeadersAddPackage.set("Content-Type", "application/json");
                requestHeadersAddPackage.set("x-api-key", subscriptionKey);
                requestHeaders.set("Authorization", token)

                const responseForPackage: Response = await fetch(`${process.env.REACT_APP_API_URL}/add-package`, {
                    method: "POST",
                    headers: requestHeadersAddPackage,
                    body,
                });

                if (
                    responseForPackage.status !== 200 &&
                    responseForPackage.status !== 201 &&
                    responseForPackage.status !== 204
                ) {
                    const requestFailedAction: RequestFailedDispatchType = {
                        type: IlionaPackagesTypes.ILIONA_ADD_PACKAGE_FAILURE,
                        payload: { errorMessage: "Something went wrong" },
                    };
                    return dispatch(requestFailedAction);
                }
                const result = await responseForPackage.json();

                const requestSuccessAction: RequestSuccessDispatchType = {
                    payload: { packages: result },
                    type: IlionaPackagesTypes.ILIONA_ADD_PACKAGE_SUCCESS,
                };
                dispatch(requestSuccessAction);
            }
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.ILIONA_ADD_PACKAGE_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};


// DELETE removes a package from the computer
export const uninstallPackageSync = (packageName: string, storeVersion: number, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(uninstallPackageSyncRequest(packageName, storeVersion, token));
    };
};

const uninstallPackageSyncRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    packageName: string, storeVersion: number, token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set('Authorization', `Bearer ${token}`)

            let body
            let apiUrl: string


            if(storeVersion === 2) {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/packagemanagement/uninstallpackage`
                body = JSON.stringify({
                    name: packageName,
                    version: ""
                });
            } else {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v1/uninstallpackage`
                body = JSON.stringify({
                    packageName: packageName,
                });
            }

            const response: Response = await fetch(apiUrl, {
                method: "POST",
                headers: requestHeaders,
                body,
            });
            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { packageDetails: result },
                type: IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.ILIONA_UNINSTALL_PACKAGE_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};




// POST installs a package sync
export const installPackageSync = (packageName: string, storeVersion: number, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(installPackageSyncRequest(packageName, storeVersion, token));
    };
};


const installPackageSyncRequest: ActionCreator<ThunkAction<Promise<any>, PackagesState, null, any>> = (
    packageName: string, storeVersion: number, token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set('Authorization', `Bearer ${token}`)

            let body
            let apiUrl: string


            if(storeVersion === 2) {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/packagemanagement/installpackage`
                body = JSON.stringify({
                    name: packageName,
                    version: ""
                });
            } else {
                apiUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v1/installpackage`
                body = JSON.stringify({
                    packageName: packageName,
                });
            }

            const response: Response = await fetch(apiUrl, {
                method: "POST",
                headers: requestHeaders,
                body,
            });

            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                payload: { packageDetails: result },
                type: IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_SUCCESS,
            };
            dispatch(requestSuccessAction);
        } catch (error) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaPackagesTypes.ILIONA_INSTALL_PACKAGE_SYNC_FAILURE,
                payload: { errorMessage: error },
            };
            dispatch(requestFailedAction);
        }
    };
};