import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IlionaCustomersTypes, CustomersState } from "./customerTypes";
import {
    RequestFailedDispatchType,
    RequestStartedDispatchType,
    RequestSuccessDispatchType,
} from "../../../models/redux/IReduxActionTypes";
import {ErrorMessagesEnum} from "../../../models/errorsEnum";

export const fetchIlionaCustomers = (subscriptionKey: string, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaCustomersRequest(subscriptionKey, token));
    };
};

// GET all customers based on ICO key
const fetchIlionaCustomersRequest: ActionCreator<ThunkAction<Promise<any>, CustomersState, null, any>> = (
    subscriptionKey: string, token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(`${process.env.REACT_APP_API_URL}/get_customers`, {
                headers: requestHeaders,
            });

            if (response && response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_SUCCESS,
                payload: { customers: result },
            };
            dispatch(requestSuccessAction);
        } catch (error: any) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_FAILURE,
                payload: { errorMessage: "Something went wrong" },
            };
            dispatch(requestFailedAction);
        }
    };
};

export const fetchIlionaCustomerPackages = (subscriptionKey: string, customerShorthandToFetch: string, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaCustomerPackagesRequest(subscriptionKey, customerShorthandToFetch, token));
    };
};

// GET all customers based on ICO key
const fetchIlionaCustomerPackagesRequest: ActionCreator<ThunkAction<Promise<any>, CustomersState, null, any>> = (
    subscriptionKey: string,
    customerShorthandToFetch: string,
    token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(
                `${process.env.REACT_APP_API_URL}/get_packages_for_customer/${customerShorthandToFetch}`,
                {
                    headers: requestHeaders,
                }
            );

            if (response && response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_SUCCESS,
                payload: { customerPackages: result },
            };
            dispatch(requestSuccessAction);
        } catch (error: any) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaCustomersTypes.FETCH_ILIONA_CUSTOMERS_PACKAGES_FAILURE,
                payload: { errorMessage: "Something went wrong" },
            };
            dispatch(requestFailedAction);
        }
    };
};



export const fetchIlionaAdminCheck = (subscriptionKey: string, token: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(fetchIlionaAdminCheckRequest(subscriptionKey, token));
    };
};

// GET to see whether user is member of an admin tenant
const fetchIlionaAdminCheckRequest: ActionCreator<ThunkAction<Promise<any>, CustomersState, null, any>> = (
    subscriptionKey: string, token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaCustomersTypes.FETCH_ADMIN_CHECK_STARTED,
        };
        dispatch(requestStartedAction);

        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(
                `${process.env.REACT_APP_API_URL}/is-admin`,
                {
                    headers: requestHeaders,
                }
            );

            if (response && response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaCustomersTypes.FETCH_ADMIN_CHECK_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();

            const requestSuccessAction: RequestSuccessDispatchType = {
                type: IlionaCustomersTypes.FETCH_ADMIN_CHECK_SUCCESS,
                payload: { data: result },
            };
            dispatch(requestSuccessAction);
        } catch (error: any) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaCustomersTypes.FETCH_ADMIN_CHECK_FAILURE,
                payload: { errorMessage: "Something went wrong" },
            };
            dispatch(requestFailedAction);
        }
    };
};




export const fetchCustomerShorthand = (subscriptionKey: string, token: string) => {

    return (dispatch: Dispatch<any>) => {
        dispatch(fetchCustomerShorthandRequest(subscriptionKey, token));
    };
};

// GET customer shorthand
const fetchCustomerShorthandRequest: ActionCreator<ThunkAction<Promise<any>, CustomersState, null, any>> = (
    subscriptionKey: string, token: string
) => {
    return async (dispatch: Dispatch) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_STARTED,
        };
        dispatch(requestStartedAction);



        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("x-api-key", subscriptionKey);
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(
                `${process.env.REACT_APP_API_URL}/identify-customer`,
                {
                    headers: requestHeaders,
                }
            );

            if (response && response.status !== 200 && response.status !== 201 && response.status !== 204) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_FAILURE,
                    payload: { errorMessage: "Something went wrong" },
                };
                return dispatch(requestFailedAction);
            }
            const result = await response.json();


            const requestSuccessAction: RequestSuccessDispatchType = {
                type: IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_SUCCESS,
                payload: { data: result },
            };
            dispatch(requestSuccessAction);
        } catch (error: any) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaCustomersTypes.FETCH_CUSTOMER_SHORTHAND_FAILURE,
                payload: { errorMessage: "Something went wrong" },
            };

            dispatch(requestFailedAction);
        }
    };
};

export const fetchStoreVersion = (token: string) => {

    return (dispatch: Dispatch<any>) => {
        dispatch(fetchStoreVersionRequest(token));
    }
}

const fetchStoreVersionRequest: ActionCreator<ThunkAction<Promise<any>, CustomersState, null, any >> = (
    token: string
) => {
    return async (dispatch: Dispatch<any>) => {
        const requestStartedAction: RequestStartedDispatchType = {
            type: IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_STARTED,
        };
        dispatch(requestStartedAction);

        try {

            let storeVersion = 1

            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set('Authorization', `Bearer ${token}`)
            const response: Response = await fetch(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/store-version`,
                {
                    headers: requestHeaders,
                }
            );

            if (response && response.status !== 200 && response.status !== 201 && response.status !== 204 && response.status !== 404) {
                const requestFailedAction: RequestFailedDispatchType = {
                    type: IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_FAILURE,
                    payload: { errorMessage: ErrorMessagesEnum.storeVersionError},
                };
                return dispatch(requestFailedAction);
            }


            if (response.status != 404) {
                storeVersion = 2
            }

            const requestSuccessAction: RequestSuccessDispatchType = {
                type: IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_SUCCESS,
                payload: { data: storeVersion },
            };
            dispatch(requestSuccessAction);
        } catch (error: any) {
            const requestFailedAction: RequestFailedDispatchType = {
                type: IlionaCustomersTypes.FETCH_STORE_AGENT_VERSION_FAILURE,
                payload: { errorMessage: ErrorMessagesEnum.noStoreAgentConnection },
            };

            dispatch(requestFailedAction);
        }
    };
}