import React from "react";
import styled from "styled-components";

import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { translateRoutePaths } from "../../i18n/CategoryTranslations";
import { IIlionaPackagesAbbreviated } from "../../models/IIlionaPackage";
import { IlionaPackageByCategory } from "../../models/IilionaPackagesByCategory";
import { IIlionaCategory } from "../../models/Ilionacategory";
import { IIlionaLocalPackage } from "../../models/ilionaLocalPackage";
import AppCard from "../appCard/AppCard";
import { Header1 } from "../html/header/Header";

interface CategoryPackagesProps {
    packagesByCategory: IlionaPackageByCategory[];
    categories: IIlionaCategory[];
    localPackages: IIlionaLocalPackage[];
    showEmptySoftwareHeaders: boolean;
    enableLinks: boolean;
}

const ContentArea = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
`;

const CategoriesPackages = ({
    packagesByCategory,
    categories,
    localPackages,
    showEmptySoftwareHeaders,
    enableLinks,
    intl,
}: CategoryPackagesProps & WrappedComponentProps) => {
    let renderItems: any = "";

    if (packagesByCategory && packagesByCategory.length > 0) {
        renderItems = packagesByCategory.map((item: IlionaPackageByCategory) => {
            if (item?.packages.length > 0) {
                return (
                    <div key={item.packages[0].displayName}>
                        <Header1>{translateRoutePaths(item.name.toLowerCase(), intl)}</Header1>
                        <ContentArea>
                            {item.packages.map((p: IIlionaPackagesAbbreviated) => {
                                if (p.isVisible) {
                                    return (
                                        <AppCard
                                            key={p?.rowKey}
                                            title={p?.displayName}
                                            packageName={p?.packageName}
                                            imageUrl={p?.imageUrl}
                                            summary={p?.summary}
                                            category={p?.category}
                                            requiresLicense={p?.requiresLicense}
                                            licenseMessage={p?.licenseMessage}
                                            rowkey={p?.rowKey}
                                            intl={intl}
                                            categories={categories}
                                            localPackages={localPackages}
                                            enableLinks={enableLinks}
                                        />
                                    );
                                }
                            })}
                        </ContentArea>
                    </div>
                );
            } else {
                if (showEmptySoftwareHeaders) {
                    return (
                        <>
                            <Header1>{translateRoutePaths(item.name.toLowerCase(), intl)}</Header1>
                            <ContentArea>
                                <FormattedMessage
                                    id="category.packages"
                                    defaultMessage="Er is momenteel geen software beschikbaar binnen deze categorie"
                                ></FormattedMessage>
                            </ContentArea>
                        </>
                    );
                }
            }
        });
    }

    return <div>{renderItems}</div>;
};

export default injectIntl(CategoriesPackages);
