import React, { useEffect, useState } from "react";
import { Spinner } from "../../components/spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IReduxApplicationState } from "../../models/redux/IReduxApplicationState";
import { fetchComputerName, fetchIlionaPackages, InstallMultiplePackage, InstallPackage } from "../../store/slices/packages/packagesActions";
import { Alert } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { fetchIlionaCategories } from "../../store/slices/categories/categoryActions";
import { NavLink, useNavigate } from "react-router-dom";
import { ErrorMessagesEnum } from "../../models/errorsEnum";
import { screenSize } from "../../themes/global";
import { Header1 } from "../../components/html/header/Header";
import { useForm } from "react-hook-form";
import { checkObjectIsEmpty, checkWhetherIsIcoAdmin } from "../../utils/general";
import { ErrorLine, FormContent, FormWrapperFullWidth, Label, SubmitButton, Textbox } from "../../styles/shared/formStyles";
import { fetchIlionaCustomers } from "../../store/slices/customers/customerActions";
import { IIlionaCustomers } from "../../models/IlionaCustomers";
import { IlionaInstallPackage } from "../../models/ilionaInstallPackage";
import useMsalToken from "../../hooks/useMsalToken";


const schema = yup.object().shape({
    packageName: yup.string().required(),
    computerName: yup.string().required(),
    customerShorthand: yup.string().required(),
});

const InstallPackageWrapper = styled.div`
    display: flex;
    grid-row: 6 / 7;
    grid-column: 1 / 13;
    flex-direction: column;

    @media ${screenSize.tablet} {
        grid-column: 2 / 12;
        margin: 3.2rem 0;
    }

    @media ${screenSize.desktop} {
        grid-column: 4 / 10;
        margin: 3.2rem 0;
    }

    input[type="checkbox"] {
        accent-color: ${(p) => p.theme.primaryColor};
    }
`;


const Select = styled.select`
    display: block;
    align-items: center;
    padding: 6px 12px;
    font-size: 1.6rem;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: ${(p) => p.theme.primaryTextColor};
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus-visible {
        outline: -webkit-focus-ring-color 1px;
        outline-color: ${(p) => p.theme.primaryColor};
        outline-style: auto;
        outline-width: 1px;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #999;
    }
`;

const TitleWrapper = styled.div`
    margin: 32px 32px 0 32px;
    display: flex;
    justify-content: space-between;

    a {
        color:  ${(p) => p.theme.primaryColor};
        display: inline-flex;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const BlockItem = styled.div`
    display: inline-flex;
    padding: 4px 8px;
    font-size: 14px;
    background: #767676;
    margin-right: 8px;
    color: white;
    margin-bottom: 16px;
`

const InstallPackageThirdParty = ({ intl }: WrappedComponentProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [confirm, setConfirm] = useState<boolean>(false);
    const [isSilentInstall, setIsSilentInstall] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const packages = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    const categories = useSelector((state: IReduxApplicationState) => state.categorySlice);
    const customers = useSelector((state: IReduxApplicationState) => state.customersSlice);
    const [showSpinner, setShowSpinner] = useState(true);
    const errorMessageComputername = useSelector(
        (state: IReduxApplicationState) => state.packagesSlice.computerNameError
    );
    const [storeVersion, setStoreVersion] = useState(1)

    const token: any = useMsalToken()

    const fetchStoreVersion = async () => {
        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            const response: Response = await fetch(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/store-version`,
                {
                    headers: requestHeaders,
                }
            )

            if(response.status === 200) {
                setStoreVersion(2)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onChange",
    });

    const enteredPackageName: any = watch("packageName"); 
    const enteredComputerName: any = watch("computerName"); 

    const onSubmit = () => {
        // check whether the formfield for packages or computer has multiple entries
        if ((enteredComputerName && enteredComputerName.includes(",")) || enteredPackageName && enteredPackageName.includes(",")) {
            let packagesArr = [];
            let computersArr = [];

            if (enteredComputerName && enteredComputerName.includes(",")) {
                computersArr = enteredComputerName.split(',').map((t: any) => {
                    if (t.trim()) {
                        return t.trim();
                    }
                })
            } else {
                computersArr.push(getValues("computerName"))
            }

            if (enteredPackageName && enteredPackageName.includes(",")) {
                packagesArr = enteredPackageName.split(',').map((t: any) => {
                    if (t.trim()) {
                        return t.trim();
                    }
                })
            } else {
                packagesArr.push(getValues("packageName"))
            }

            const valuesToSend: IlionaInstallPackage = {
                packageNames: packagesArr,
                computerNames: computersArr,
                customerShorthand: getValues("customerShorthand"),
                isSilentInstall,
                subscriptionKey: packages?.subscriptionKey,
            }

            dispatch(
                InstallMultiplePackage(valuesToSend, token)
            );

            return navigate("/appstore/admin/home", { replace: true });

        } else {
            dispatch(
                InstallPackage(
                    "",
                    getValues("packageName"),
                    getValues("computerName"),
                    getValues("customerShorthand"),
                    isSilentInstall,
                    packages?.subscriptionKey,
                    "install-package-on-computer",
                    token
                )
            );

            return navigate("/appstore/admin/home", { replace: true });
        }

   
    };

    useEffect(() => {
        fetchStoreVersion()
    }, []);

    useEffect(() => {
        dispatch(fetchComputerName(storeVersion, token));
    }, [dispatch]);

    useEffect(() => {
        if (packages?.subscriptionKey != "") {
            dispatch(fetchIlionaPackages(packages?.subscriptionKey, token));
            if (categories?.categories && categories.categories.length === 0 && packages?.subscriptionKey !== "") {
                dispatch(fetchIlionaCategories(packages?.subscriptionKey, token));
                dispatch(fetchIlionaCustomers(packages?.subscriptionKey, token));
            }
            checkWhetherIsIcoAdmin(packages?.subscriptionKey, navigate);
        }
    }, [dispatch, packages?.subscriptionKey]);

    useEffect(() => {
        if (errorMessageComputername === ErrorMessagesEnum.noCSAClientFound) {
            return navigate("/notallowed", { replace: true });
        } else {
            setShowSpinner(false);
        }
    }, [errorMessageComputername, packages.computerName]);

    let packagesArray: string[] = [];
    if (enteredPackageName && enteredPackageName.includes(",")) {
        const temp = enteredPackageName.split(',');
        packagesArray = temp.map((t: any) => {
            if (t.trim()) {
                return t.trim();
            }
        })
    }

    let computersArray: string[] = [];
    if (enteredComputerName && enteredComputerName.includes(",")) {
        const temp = enteredComputerName.split(',');
        computersArray = temp.map((t: any) => {
            if (t.trim()) {
                return t.trim();
            }
        })
    }
    

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log(value, name, type));
        return () => subscription.unsubscribe();
      }, [watch]);


    return (
        <>
            {(showSpinner && <Spinner />) || (!packages.computerName && <Spinner />)}

            <InstallPackageWrapper>
                {!showSpinner && showAlert && (
                    <Alert variant="danger" className="mb-4" dismissible onClose={() => setShowAlert(false)}>
                        <FormattedMessage
                            id="errormessages.general"
                            defaultMessage="Er is iets fout gegaan, probeer het later opnieuw."
                        ></FormattedMessage>
                    </Alert>
                )}

                {packages?.errorMessage && !showSpinner && packages.computerName && (
                    <Alert variant="danger" className="mb-4">
                        Er is iets fout gegaan. Probeer het later nogmaals.
                    </Alert>
                )}

                {!showSpinner && packages.computerName && (
                    <div style={{ background: "#fff" }}>
                        <TitleWrapper>
                            <Header1>
                                <FormattedMessage
                                    id="thirdparty.install.package"
                                    defaultMessage="Installeer package"
                                ></FormattedMessage>
                            </Header1>
                            <NavLink to={`/appstore/admin/home`} role="link">Terug naar admin overizcht</NavLink>
                        </TitleWrapper>
                        <form onSubmit={handleSubmit(() => onSubmit())}>
                            <FormContent>
                                <FormWrapperFullWidth>
                                    <div>
                                        <Label htmlFor="packageName">
                                            <FormattedMessage
                                                id="thirdparty.package.name"
                                                defaultMessage="Pakketnaam"
                                            ></FormattedMessage>
                                        </Label>
                                        {errors.packageName && (
                                            <ErrorLine role="alert" aria-label="pakketnaam">
                                                <FormattedMessage
                                                    id="thirdparty.package.name.required"
                                                    defaultMessage="Pakketnaam is vereist"
                                                ></FormattedMessage>
                                            </ErrorLine>
                                        )}
                                        <Textbox
                                            {...register("packageName")}
                                            id="packageName"
                                            name="packageName"
                                            autoFocus
                                            tabIndex={1}
                                            style={{marginBottom: '8px'}}
                                        />
                                        {packagesArray.length > 0 && packagesArray.map((packageNameEntry: string) => {
                                            if (packageNameEntry) {
                                                return (<BlockItem>{packageNameEntry}</BlockItem>)
                                            }
                                        }
                                        )}

                                        {packagesArray.length === 0 && enteredPackageName && enteredPackageName.trim() && (
                                                <BlockItem>{enteredPackageName}</BlockItem>
                                            )
                                        }
                                            
                                
                                    </div>

                                    <div>
                                        <Label htmlFor="computerName">
                                            <FormattedMessage
                                                id="thirdparty.computer.name"
                                                defaultMessage="Computernaam"
                                            ></FormattedMessage>
                                        </Label>
                                        {errors.computerName && (
                                            <ErrorLine role="alert" aria-label="computernaam">
                                                <FormattedMessage
                                                    id="thirdparty.computer.name.required"
                                                    defaultMessage="Computernaam is vereist"
                                                ></FormattedMessage>
                                            </ErrorLine>
                                        )}
                                        <Textbox
                                            {...register("computerName")}
                                            id="computerName"
                                            name="computerName"
                                            tabIndex={2}
                                            style={{marginBottom: '8px'}}
                                            />
                                            {computersArray.length > 0 && computersArray.map((computerNameEntry: string) => {
                                                if (computerNameEntry) {
                                                    return (<BlockItem>{computerNameEntry}</BlockItem>)
                                                }
                                            }
                                            )}
    
                                            {computersArray.length === 0 && enteredComputerName && enteredComputerName.trim() && (
                                                    <BlockItem>{enteredComputerName}</BlockItem>
                                                )
                                            }
                                       
                                    </div>
                                    <div>
                                        <Label htmlFor="customerShorthand">
                                            <FormattedMessage
                                                id="thirdparty.customer.shorthand"
                                                defaultMessage="Afkorting klantnaam"
                                            ></FormattedMessage>
                                        </Label>
                                        {errors.customerShorthand && (
                                            <ErrorLine role="alert" aria-label="customerShorthand">
                                                <FormattedMessage
                                                    id="thirdparty.customer.shorthand.required"
                                                    defaultMessage="Afkorting klantnaam is vereist"
                                                ></FormattedMessage>
                                            </ErrorLine>
                                        )}
                                         <Select
                                                    {...register("customerShorthand")}
                                            id="customer"
                                            tabIndex={3}
                                            name="customerShorthand"
                                            style={{marginBottom: '16px'}}
                                        >
                                             {customers?.customers.map((customer: IIlionaCustomers) => {
                                                return (
                                                    <option key={customer?.customerShorthand} value={customer?.customerShorthand}>
                                                        {customer?.customerName}
                                                    </option>
                                                );
                                             })
                                             }
                                        </Select>
                                    </div>
                                    <Label
                                        htmlFor="isSilentInstall"
                                        style={{
                                            display: "inline-block",
                                            marginRight: "32px",
                                            marginBottom: "16px",
                                        }}
                                    >
                                        <input
                                            onChange={() => setIsSilentInstall(!isSilentInstall)}
                                            type="checkbox"
                                            name="isSilentInstall"
                                            value={isSilentInstall.toString()}
                                            checked={isSilentInstall}
                                            id="isSilentInstall"
                                            style={{ display: "inline-block" }}
                                            tabIndex={4}
                                        />
                                        &nbsp;
                                        <span>
                                            <FormattedMessage
                                                id="thirdparty.show.notification"
                                                defaultMessage="Toon toast notificatie na installatie"
                                            ></FormattedMessage>
                                        </span>
                                    </Label>
                                    <Label
                                        htmlFor="confirm"
                                        style={{
                                            display: "inline-block",
                                            marginRight: "32px",
                                        }}
                                    >
                                        <input
                                            onChange={() => setConfirm(!confirm)}
                                            type="checkbox"
                                            name="confirm"
                                            value={confirm.toString()}
                                            checked={confirm}
                                            id="confirm"
                                            style={{ display: "inline-block" }}
                                            tabIndex={5}
                                        />
                                        &nbsp;
                                        <span>
                                            <FormattedMessage
                                                id="thirdparty.package.install..confirmation.1"
                                                defaultMessage="Weet u zeker dat u "
                                            ></FormattedMessage>{" "}
                                            "{getValues("packageName")}"{" "}
                                            <FormattedMessage
                                                id="thirdparty.package.install..confirmation.2"
                                                defaultMessage=" op computer(s) "
                                            ></FormattedMessage>{" "}
                                            "{getValues("computerName")}"{" "}
                                            <FormattedMessage
                                                id="thirdparty.package.install..confirmation.3"
                                                defaultMessage=" wilt installeren?"
                                            ></FormattedMessage>
                                        </span>
                                    </Label>
                                    <SubmitButton
                                        customText="Installeer package"
                                        disabled={!checkObjectIsEmpty(errors) || !confirm}
                                    />
                                </FormWrapperFullWidth>
                            </FormContent>
                        </form>
                    </div>
                )}
            </InstallPackageWrapper>
        </>
    );
};

export default injectIntl(InstallPackageThirdParty);
