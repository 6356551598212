import React, {useEffect, useState} from "react";
import { ThemeProvider } from "styled-components";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider} from "react-router-dom";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
// redux
import {IReduxApplicationState} from "./models/redux/IReduxApplicationState";
import { fetchSubscriptionKey} from "./store/slices/packages/packagesActions";
import {fetchStoreVersion} from "./store/slices/customers/customerActions";
// pages
import Appstore from "./pages/Appstore";
import LandingPage from "./pages/Landing";
import {Bitlocker} from "./pages/Bitlocker";
// components
import {RootLayout} from "./layouts/RootLayout";
// styling
import {ilionxTheme} from "./themes/ilionx";
import {themeSelector} from "./themes/themeSelector";
// hooks
import useMsalToken from "./hooks/useMsalToken";
import {Support} from "./pages/Support";

interface AppProps extends WrappedComponentProps {
    onLocaleChange: (locale: string) => void;
}


function App({onLocaleChange}: AppProps) {
    const dispatch = useDispatch();

    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const storeVersion = useSelector((state: IReduxApplicationState) => state.customersSlice.storeVersion);

    const [theme, setTheme] = useState(ilionxTheme);
    const [appInitialLoading, SetAppInitialLoading] = useState(true);

    const token: any = useMsalToken()
    const fetchSubscriptionKeyData = async () => {
            dispatch(fetchSubscriptionKey(storeVersion, token));
    };

    const getInitialData = async () => {

        if (subscriptionKey != "") {
            setTheme(themeSelector("ilionx"));
            SetAppInitialLoading(false);
        }
    };

    useEffect(() => {
        if(token) {
            dispatch(fetchStoreVersion(token))

        }
    }, [token]);

    useEffect(() => {
        if (storeVersion != 0) {
            fetchSubscriptionKeyData();
        }
    }, [storeVersion]);


    useEffect(() => {
        getInitialData();
    }, [subscriptionKey]);


    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RootLayout onLocaleChange={onLocaleChange} appState={appInitialLoading}/>}>
                <Route index element={<LandingPage/>}/>
                <Route path="/appstore/*" element={<Appstore/>} />
                <Route path="/bitlocker/*" element={<Bitlocker/>} />
                <Route path="/support/*" element={<Support/>} />
            </Route>
        )
    )

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>

    )
}

export default injectIntl(App);