import React, {useState} from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/default.scss";
import { IntlProvider } from "react-intl";
import { translationSets } from "./i18n/translations";
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./config/authConfig";

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_MSW) {
    const { worker } = require("./mocks/browser");
    worker.start();
}

const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;

            console.log(account)

            msalInstance.setActiveAccount(account);



            // TODO Should frontend keep a list af tenants that can access the application?
            // const tenantId = account.tenantId;
            //
            // const allowedTenants = ["e5eafdfe-6d22-41b6-bde3-8212b22bfba4"];
            //
            // if (allowedTenants.includes(tenantId)) {
            //     msalInstance.setActiveAccount(account);
            // } else {
            //     alert("You do not have access to this application.");
            //     msalInstance.logout()
            // }
        }
    });

});


function Root() {
    const [locale, setLocale] = useState(localStorage.language || "nl");
    const [messages, setMessages] = useState(translationSets[locale as keyof typeof translationSets]);

    const changeLocale = (newLocale: string) => {
        setLocale(newLocale);
        setMessages(translationSets[newLocale as keyof typeof translationSets]);
    };

    return (
        <MsalProvider instance={msalInstance}>
            <IntlProvider locale={locale} messages={messages}>
                <Provider store={store}>
                        <App onLocaleChange={changeLocale}/>
                </Provider>
            </IntlProvider>
        </MsalProvider>
    )
}

ReactDOM.render(
    <Root/>,
    document.getElementById("root")
);
