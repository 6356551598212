import React, {useCallback, useEffect, useState} from "react";
import {Spinner} from "../components/spinner/Spinner";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {IReduxApplicationState} from "../models/redux/IReduxApplicationState";
import {
    fetchComputerName,
    fetchIlionaPackages,
    fetchLocalPackages,
    fetchSubscriptionKey,
    uninstallPackageSync,
    removeUninstallAlert,
} from "../store/slices/packages/packagesActions";
import {Alert, Button} from "react-bootstrap";
import {IlionaPackageByCategory} from "../models/IilionaPackagesByCategory";
import {filterPackagesPerCategory} from "../utils/orderPackagesByCategory";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {fetchIlionaCategories} from "../store/slices/categories/categoryActions";
import {useNavigate} from "react-router-dom";
import {ErrorMessagesEnum} from "../models/errorsEnum";
import {Header1} from "../components/html/header/Header";
import {checkObjectIsEmpty} from "../utils/general";
import {IIlionaPackagesAbbreviated} from "../models/IIlionaPackage";
import {screenSize} from "../themes/global";
import {ScreenOverlay} from "../components/screenOverlay/ScreenOverlay";
import useMsalToken from "../hooks/useMsalToken";

const MainContent = styled.div`
  display: flex;
  grid-row: 6 / 7;
  grid-column: 2 / 12;
  margin: 3.2rem 0;
  flex-direction: column;
`;

const PackageLineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  width: 100%;
  height: 56px;
  margin-bottom: 16px;
  background: #fff;
  border: 1px solid #ccc;

  & div {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .app-icon {
    display: none;
  }

  .delete-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
  }

  .packagename-wrapper {
    padding-left: 8px;
  }

  button {
    font-size: 16px;
  }

  @media ${screenSize.tablet} {
    grid-template-columns: 60px 1fr 150px;

    .app-icon {
      justify-content: center;
      width: 100%;
      display: flex;

      img {
        height: 40px;
        width: 40px;
      }
    }
  }
`;

const UninstallPackage = ({intl}: WrappedComponentProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showSpinner, setShowSpinner] = useState(true);

    const packages = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    const categories = useSelector((state: IReduxApplicationState) => state.categorySlice);
    const [showError, setShowError] = useState(false);
    const computerName = useSelector((state: IReduxApplicationState) => state.packagesSlice.computerName);
    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const [lastPackageUninstalled, setLastPackageUninstalled] = useState<string>("");
    const [lastPackageUninstalledDisplayName, setLastPackageUninstalledDisplayName] = useState<string>("");
    const [categoriesWithPackages, setCategoriesWithPackages] = useState<IlionaPackageByCategory[]>([]);
    const [installedPackagesInStore, setInstalledPackagesInStore] = useState<IIlionaPackagesAbbreviated[]>([]);
    const errorMessageComputername = useSelector(
        (state: IReduxApplicationState) => state.packagesSlice.computerNameError
    );
    const [storeVersion, setStoreVersion] = useState(1)
    const token: any = useMsalToken()


    const fetchStoreVersion = async () => {
        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            const response: Response = await fetch(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/store-version`,
                {
                    headers: requestHeaders,
                }
            )

            if(response.status === 200) {
                setStoreVersion(2)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchStoreVersion()
    }, []);


    useEffect(() => {
        dispatch(fetchComputerName(storeVersion, token));
        dispatch(fetchSubscriptionKey(storeVersion, token));
    }, [dispatch]);

    useEffect(() => {
        if (computerName && subscriptionKey != "") {
            dispatch(fetchLocalPackages(computerName, subscriptionKey, storeVersion, token));
        }
    }, [computerName, subscriptionKey]);

    useEffect(() => {
        if (packages?.subscriptionKey != "") {
            dispatch(fetchIlionaPackages(packages?.subscriptionKey, token));
            if (categories?.categories && categories.categories.length === 0 && packages?.subscriptionKey !== "") {
                dispatch(fetchIlionaCategories(packages?.subscriptionKey, token));
            }
        }
    }, [dispatch, packages?.subscriptionKey]);

    useEffect(() => {
        if (errorMessageComputername === ErrorMessagesEnum.noCSAClientFound) {
            return navigate("/notallowed", {replace: true});
        }
    }, [errorMessageComputername, packages.computerName]);

    useEffect(() => {
        dispatch(fetchLocalPackages(computerName, subscriptionKey, storeVersion, token));
    }, [packages?.showOverlay])


    useEffect(() => {
        if (
            packages?.ilionaPackages &&
            packages?.ilionaPackages.length > 0 &&
            categories?.categories &&
            categories?.categories.length > 0
        ) {
            setCategoriesWithPackages(filterPackagesPerCategory(categories?.categories, packages?.ilionaPackages));
        }
    }, [packages?.ilionaPackages, categories?.categories]);

    useEffect(() => {
        localPackagesThatExistInStore();
    }, [categoriesWithPackages, packages?.locallyInstalledPackages]);

    if (categories?.errorMessage) {
        setShowError(true);
    }

    const localPackagesThatExistInStore = () => {
        const packagesToShow: IIlionaPackagesAbbreviated[] = packages?.ilionaPackages.filter(
            (ilionaPackage: IIlionaPackagesAbbreviated) => {
                const packageObj = packages?.locallyInstalledPackages.find(
                    (lp) => lp.name === ilionaPackage.packageName
                );
                if (packageObj && !checkObjectIsEmpty(packageObj)) {
                    return packageObj;
                }
            }
        );
        setInstalledPackagesInStore(packagesToShow);
    };

    async function uninstallPackage(packageName: string, displayName: string) {
        setLastPackageUninstalled(packageName);
        setLastPackageUninstalledDisplayName(displayName);
        await dispatch(uninstallPackageSync(packageName, storeVersion, token));

    }

    function removeAlert() {
        dispatch(removeUninstallAlert());
    }

    const content = installedPackagesInStore.map((packageObj: IIlionaPackagesAbbreviated) => {
        showSpinner && setShowSpinner(false);
        return (
            <PackageLineWrapper key={packageObj?.rowKey}>
                <div className="app-icon">
                    <img src={process.env.REACT_APP_BLOB_STORAGE_URL + packageObj.imageUrl}/>
                </div>
                <div className="packagename-wrapper">{packageObj?.displayName}</div>
                <div className="delete-wrapper">
                    <Button variant="danger"
                            onClick={() => uninstallPackage(packageObj?.packageName, packageObj?.displayName)}>
                        <FormattedMessage id="general.remove" defaultMessage="Verwijderen"></FormattedMessage>
                    </Button>
                </div>
            </PackageLineWrapper>
        );
    });

    return (
        <MainContent>
            {showSpinner && (
                <p>
                    <Spinner/>
                </p>
            )}
            {showError && !showSpinner && packages.computerName &&
                <Alert variant="danger">
                    <FormattedMessage
                        id="errormessages.general"
                        defaultMessage="Er is iets fout gegaan, probeer het later opnieuw"
                    ></FormattedMessage>
                </Alert>}

            {packages?.errorMessage && !showSpinner && packages.computerName && (
                <Alert variant="danger" className="mb-4">
                    <FormattedMessage
                        id="errormessages.general"
                        defaultMessage="Er is iets fout gegaan, probeer het later opnieuw"
                    ></FormattedMessage>
                </Alert>
            )}

            {!showSpinner && packages?.packageUninstallFailed && (
                <Alert variant="danger" className="mb-4" dismissible onClose={() => removeAlert()}>
                    <FormattedMessage
                        id="errormessages.general"
                        defaultMessage="Er is iets fout gegaan, probeer het later opnieuw"
                    ></FormattedMessage>
                </Alert>
            )}
            {!showSpinner && packages?.packageUninstallSuccessful && (
                <Alert variant="success" className="mb-4" dismissible onClose={() => removeAlert()}>
                    {lastPackageUninstalled}
                    <FormattedMessage
                        id="uninstalled-succesfully"
                        defaultMessage=" is gedeïnstalleerd"
                    ></FormattedMessage>
                </Alert>
            )}

            {!showSpinner && packages.computerName && (
                <>
                    <Header1>
                        <FormattedMessage
                            id="packages.installed.packages"
                            defaultMessage="Geïnstalleerde software"
                        ></FormattedMessage>
                    </Header1>
                    {content}
                </>
            )}

            {!showSpinner && packages.showOverlay && (
                <ScreenOverlay>
                    <Header1>
                        {lastPackageUninstalledDisplayName}

                        <FormattedMessage
                            id="overlay.package.uninstall.header"
                            defaultMessage="  wordt gedeïnstalleerd"
                        ></FormattedMessage>
                    </Header1>
                    <p style={{color: '#BDBDBD'}}>
                        <FormattedMessage
                            id="overlay.package.uninstall.paragraph"
                            defaultMessage="Dit kan enkele momenten duren afhankelijk van de grootte van het pakket."
                        ></FormattedMessage>
                    </p>
                    <Spinner style={{height: '140px'}}/>
                </ScreenOverlay>
            )}

        </MainContent>
    );
};

export default injectIntl(UninstallPackage);
