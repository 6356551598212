import {Header1} from "../html/header/Header";
import React from "react";
import styled from "styled-components";

const MainContent = styled.div`
    display: flex;
    grid-row: 6 / 7;
    grid-column: 2 / 12;
    margin: 3.2rem 0;
    flex-direction: column;
    text-align: center;
`;

const ErrorFailedConnectingStoreAgent = (errorMsg: any) => {

    return (
        <MainContent>
            <div role="alert">
                <Header1>
                    Error: {errorMsg.errorMsg}
                </Header1>
            </div>
        </MainContent>
    )
}

export default ErrorFailedConnectingStoreAgent