import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { screenSize } from "../../themes/global";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Header1 } from "../../components/html/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { IReduxApplicationState } from "../../models/redux/IReduxApplicationState";
import { IIlionaCategory } from "../../models/Ilionacategory";
import { checkFileMimetype, checkWhetherIsIcoAdmin } from "../../utils/general";
import { fetchIlionaCategories } from "../../store/slices/categories/categoryActions";
import { AddPackageRedux, closeSuccessInstalledMessage } from "../../store/slices/packages/packagesActions";
import { Alert } from "react-bootstrap";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Textbox } from "../../styles/shared/formStyles";
import { Spinner } from "../../components/spinner/Spinner";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchIlionaCustomers } from "../../store/slices/customers/customerActions";
import { IIlionaCustomers } from "../../models/IlionaCustomers";
import { FormattedMessage, IntlShape } from "react-intl";
import { translateRoutePaths } from "../../i18n/CategoryTranslations";
import useMsalToken from "../../hooks/useMsalToken";

const schema = yup
    .object({
        package_name: yup.string().required(),
        installation_time: yup.number().positive().integer().required(),
        category: yup.string().required(),
        customer_shorthand: yup.string().required(), 
        dependencies: yup.string(),
        description: yup.string().required(),
        display_name: yup.string().required(),
        is_visible: yup.boolean().required(),
        license_message: yup.string().required(),
        need_to_restart: yup.boolean().required(),
        summary: yup.string().required(),
        publish_date: yup.string(),
        source_repository: yup.string(),
        tags: yup.string(),
        weight: yup.number().positive().integer().required(),
    })
    .required();

const AddPackageWrapper = styled.div`
    display: flex;
    grid-row: 6 / 7;
    grid-column: 1 / 13;
    flex-direction: column;

    @media ${screenSize.tablet} {
        grid-column: 2 / 12;
        margin: 3.2rem 0;
    }

    @media ${screenSize.desktop} {
        grid-column: 4 / 10;
        margin: 3.2rem 0;
    }
`;

const ContentAreaWrapper = styled.div`
    background: white;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 4px;
`;

const ErrorLine = styled.p`
    color: darkred;
    font-style: italic;
    margin-bottom: 4px;
`;

const TitleWrapper = styled.div`
    margin: 32px 32px 0 32px;
    display: flex;
    justify-content: space-between;

    a {
        color:  ${(p) => p.theme.primaryColor};
        display: inline-flex;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const TextArea = styled.textarea`
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${(p) => p.theme.primaryTextColor};
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 24px;
    height: 130px;

    &:focus-visible {
        outline: -webkit-focus-ring-color 1px;
        outline-color: ${(p) => p.theme.primaryColor};
        outline-style: auto;
        outline-width: 1px;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #999;
    }
`;

const Select = styled.select`
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${(p) => p.theme.primaryTextColor};
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 24px;

    &:focus-visible {
        outline: -webkit-focus-ring-color 1px;
        outline-color: ${(p) => p.theme.primaryColor};
        outline-style: auto;
        outline-width: 1px;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #999;
    }
`;

const FormContent = styled.div`
    display: flex;
    flex-direction: row;
`;

const FormWrapper = styled.div`
    display: inline-flex;
    width: calc(60% - 64px);
    padding: 32px;
    flex-direction: column;
`;

const ImageWrapper = styled.div`
    display: inline-flex;
    width: 40%;
    margin-top: 60px;
    flex-direction: column;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid #ced4da;
    width: 100%;
    height: 170px;
`;

const FormItemsHalfSizeWrapper = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
`;

interface SubmitButtonProps { 
    text: string
}

const SubmitButton = styled.input.attrs<SubmitButtonProps>(
    ({text}) => ({
    type: "submit",
    value: text
}))<SubmitButtonProps>`
    padding: 4px 64px;
    background-color: ${(p) => p.theme.primaryColor};
    color: white;
    outline: none;
    border: none;
    font-size: 14px;

    &:disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
`;

const AddPackage = ({ intl }: WrappedComponentProps) => {
    const navigate = useNavigate();
    const categories = useSelector((state: IReduxApplicationState) => state.categorySlice);
    const packages = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    const customers = useSelector((state: IReduxApplicationState) => state.customersSlice);
    const subscriptionkey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const [image, setImage] = useState<File | undefined>(undefined);
    const [imageFile, setimageFile] = useState("");
    const [imageError, setimageError] = useState<string>("");
    const [showSpinner, setShowSpinner] = useState(true);

    const dispatch = useDispatch();
    let showError = false;

    const token: any = useMsalToken()

    useEffect(() => {
        if (categories?.categories && categories?.categories.length === 0 && subscriptionkey) {
            dispatch(fetchIlionaCategories(subscriptionkey, token));
        }
    }, []);

    useEffect(() => {
        if (categories?.categories && categories?.categories.length > 0) {
            setValue("category", categories.categories[0].RowKey);
            setShowSpinner(false);
        }
        if (subscriptionkey) {
            checkWhetherIsIcoAdmin(packages?.subscriptionKey, navigate);
            dispatch(fetchIlionaCustomers(subscriptionkey, token));
        }
    }, [categories, subscriptionkey]);

    useEffect(() => {
        if (imageError && imageError !== "") {
            setimageError(imageError);
        }
    }, [imageError]);

    const errorText = intl.formatMessage({
        id: "errormessages.general",
        defaultMessage: "Er is iets fout gegaan, probeer het later opnieuw.",
    });

    if (categories?.errorMessage) {
        showError = true;
    }

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    });
    const onSubmit = (data: any) => {
        data.image_url = image?.name;
        data.is_already_installed = false;
        data.publish_date = Date.now();
        data.requires_license = true;
        data.source_repository = '';

        dispatch(AddPackageRedux(data, image, subscriptionkey, token));
        setImage(undefined);

        return navigate("/appstore/admin/home", { replace: true });
    };

    const generateFile = (event: any) => {
        setimageFile(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <>
            <AddPackageWrapper>
                {showSpinner && (
                    <p data-testid="fake-spinner">
                        <Spinner />
                    </p>
                )}
                {showError && !showSpinner && packages.computerName && <Alert variant="danger">{errorText}</Alert>}
                {packages?.packageAdded && !showSpinner && packages.computerName && (
                    <Alert
                        variant="success"
                        className="mb-4"
                        dismissible
                        onClose={() => dispatch(closeSuccessInstalledMessage())}
                    >
                        <FormattedMessage
                            id="admin.addpackage.add.toast"
                            defaultMessage="Het pakket is toegevoegd."
                        ></FormattedMessage>
                        .
                    </Alert>
                )}
                {packages?.errorMessage && !showSpinner && packages.computerName && (
                    <Alert variant="danger" className="mb-4">
                        <FormattedMessage
                            id="admin.addpackage.add.toast.error"
                            defaultMessage="Er is iets fout gegaan. Probeer het later nogmaals."
                        ></FormattedMessage>
                    </Alert>
                )}

                {!showSpinner && !showSpinner && packages.computerName && (
                    <ContentAreaWrapper>
                        <TitleWrapper>
                            <Header1>
                                <FormattedMessage
                                    id="admin.add.package.header"
                                    defaultMessage="Pakket toevoegen"
                                ></FormattedMessage>
                            </Header1>
                            <NavLink to={`/appstore/admin/home`} role="link">
                                <FormattedMessage
                                    id="admin.addpackage.back.button"
                                    defaultMessage="Terug naar admin overizcht"
                                ></FormattedMessage>
                            </NavLink>
                        </TitleWrapper>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormContent>
                                <FormWrapper>
                                    <Label htmlFor="display_name">
                                        <FormattedMessage
                                                id="admin.addpackage.displayname"
                                                defaultMessage="Naam"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.display_name && (
                                        <ErrorLine role="alert" aria-label="display name">
                                            <FormattedMessage
                                                    id="admin.addpackage.displayname.required"
                                                    defaultMessage="Categorie"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <Textbox
                                        {...register("display_name")}
                                        id="display_name"
                                        name="display_name"
                                        placeholder={
                                            intl.formatMessage({
                                                id: "admin.addpackage.displayname.placeholdder",
                                                defaultMessage: "Geef een weergave naam op",
                                            })
                                        }
                                        autoFocus
                                    />

                                    <Label htmlFor="category">
                                        <FormattedMessage
                                                id="admin.addpackage.category"
                                                defaultMessage="Categorie"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.category && (
                                        <ErrorLine role="alert" aria-label="category">
                                            <FormattedMessage
                                                    id="admin.addpackage.category.required"
                                                    defaultMessage="Selecteer een categorie"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}

                                    <Select {...register("category")} id="category">
                                        {categories?.categories.map((cat: IIlionaCategory) => {
                                            return (
                                                <option key={cat?.RowKey} value={cat?.RowKey}>
                                                    {translateRoutePaths(cat?.Name, intl)}
                                                </option>
                                            );
                                        })}
                                    </Select>

                                    <Label htmlFor="customer_shorthand">
                                            <FormattedMessage
                                                    id="admin.addpackage.customershorthand"
                                                    defaultMessage="Afkorting klant"
                                            ></FormattedMessage>
                                    </Label>
                                    {errors.customer_shorthand && (
                                        <ErrorLine role="alert" aria-label="customer_shorthand">
                                             <FormattedMessage
                                                    id="admin.addpackage.customershorthand.required"
                                                    defaultMessage="Geef een afkorting op van de klant"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                  <Select
                                        {...register("customer_shorthand")}
                                        id="customer_shorthand"
                                        tabIndex={3}
                                        name="customer_shorthand"
                                        style={{marginBottom: '16px'}}
                                    >
                                            {customers?.customers.map((customer: IIlionaCustomers) => {
                                                return (
                                                    <option key={customer?.customerShorthand} value={customer?.customerShorthand}>
                                                        {customer?.customerName}
                                                    </option>
                                                );
                                            })
                                        }
                                </Select>

                                    <Label htmlFor="need_to_restart">
                                        <FormattedMessage
                                                id="admin.addpackage.restart"
                                                defaultMessage="Herstart nodig"
                                        ></FormattedMessage>
                                    </Label>

                                    <Select {...register("need_to_restart")} id="need_to_restart" defaultValue="true">
                                        <option value="false">
                                            { intl.formatMessage({
                                                id: "general.no",
                                                defaultMessage: "Nee",
                                            })}
                                        </option>
                                        <option value="true">
                                        { intl.formatMessage({
                                                id: "general.yes",
                                                defaultMessage: "Ja",
                                            })}
                                        </option>
                                    </Select>

                                    <FormItemsHalfSizeWrapper>
                                        <div>
                                            <Label htmlFor="installation_time">
                                                <FormattedMessage
                                                        id="admin.addpackage.installationtime"
                                                        defaultMessage="Installatietijd"
                                                ></FormattedMessage>
                                            </Label>
                                            {errors.installation_time && (
                                                <ErrorLine role="alert" aria-label="installation">
                                                    <FormattedMessage
                                                        id="admin.addpackage.installationtime.required"
                                                        defaultMessage="Geef de installatietijd op als nummer"
                                                    ></FormattedMessage>
                                                </ErrorLine>
                                            )}
                                            <Textbox
                                                {...register("installation_time")}
                                                id="installation_time"
                                                name="installation_time"
                                                style={{
                                                    display: "inline-block",
                                                    width: "calc(100% - 16px)",
                                                }}
                                                placeholder={
                                                    intl.formatMessage({
                                                        id: "admin.addpackage.installationtime.placeholdder",
                                                        defaultMessage: "Installatietijd in minuten",
                                                    })
                                                }
                                            />
                                        </div>

                                        <div>
                                            <Label htmlFor="weight">
                                                <FormattedMessage
                                                    id="admin.addpackage.weight"
                                                    defaultMessage="Orderning"
                                                ></FormattedMessage>
                                            </Label>
                                            {errors.weight && (
                                                <ErrorLine role="alert" aria-label="weight">
                                                      <FormattedMessage
                                                            id="admin.addpackage.weight.required"
                                                            defaultMessage="Geef de positie op"
                                                    ></FormattedMessage>
                                                </ErrorLine>
                                            )}
                                            <Textbox
                                                {...register("weight")}
                                                id="weight"
                                                name="weight"
                                                style={{ display: "inline-block" }}
                                                placeholder={
                                                    intl.formatMessage({
                                                        id: "admin.addpackage.weight.placeholdder",
                                                        defaultMessage: "Positie van het pakket, van hoog naar laag",
                                                    })
                                                }
                                            />
                                        </div>
                                    </FormItemsHalfSizeWrapper>

                                    <Label htmlFor="tags">
                                        <FormattedMessage
                                                id="admin.addpackage.tags"
                                                defaultMessage="Labels"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.tags && (
                                        <ErrorLine role="alert" aria-label="tags">
                                            <FormattedMessage
                                                    id="admin.addpackage.tags.required"
                                                    defaultMessage="Geef een label op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <Textbox
                                        {...register("tags")}
                                        id="tags"
                                        name="tags"
                                        style={{ display: "inline-block" }}
                                        placeholder={
                                            intl.formatMessage({
                                                id: "admin.addpackage.tags.placeholdder",
                                                defaultMessage: "Labels om de pakketen te ordenen",
                                            })
                                        }
                                    />

                                    <Label htmlFor="summary">
                                        <FormattedMessage
                                            id="admin.addpackage.summary"
                                            defaultMessage="Korte omschrijving"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.summary && (
                                        <ErrorLine role="alert" aria-label="summary">
                                            <FormattedMessage
                                                id="admin.addpackage.summary.required"
                                                defaultMessage="Geef een korte omschrijving op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <Textbox
                                        {...register("summary")}
                                        id="summary"
                                        name="summary"
                                        placeholder={
                                            intl.formatMessage({
                                                id: "admin.addpackage.summary.placeholdder",
                                                defaultMessage: "Een korte omschrijving van het pakket",
                                            })
                                        }
                                    />

                                    <Label htmlFor="description">
                                        <FormattedMessage
                                            id="admin.addpackage.description"
                                            defaultMessage="Volledige beschrijving"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.description && (
                                        <ErrorLine role="alert" aria-label="description">
                                            <FormattedMessage
                                                id="admin.addpackage.description.required"
                                                defaultMessage="Geef een volledige omschrijving op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <TextArea
                                        {...register("description")}
                                        id="description"
                                        name="description"
                                        placeholder={
                                            intl.formatMessage({
                                                id: "admin.addpackage.description.placeholdder",
                                                defaultMessage: "Een volledige omschrijving van het pakket",
                                            })
                                        }
                                    />

                                    <Label htmlFor="package_name">
                                        <FormattedMessage
                                            id="admin.addpackage.packagename"
                                            defaultMessage="Pakketnaam"
                                        ></FormattedMessage>

                                    </Label>
                                    {errors.package_name && (
                                        <ErrorLine role="alert" aria-label="package name">
                                            <FormattedMessage
                                                id="admin.addpackage.packagename.required"
                                                defaultMessage="Geef een pakketnaam op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <Textbox
                                        {...register("package_name")}
                                        id="package_name"
                                        name="package_name"
                                        placeholder={
                                            intl.formatMessage({
                                                id: "admin.addpackage.packagename.placeholdder",
                                                defaultMessage: "Chocolatey pakketnaam",
                                            })
                                        }
                                    />

                                    <Label htmlFor="dependencies">
                                        <FormattedMessage
                                            id="admin.addpackage.dependencies"
                                            defaultMessage="Afhankelijkheden van software"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.dependencies && (
                                        <ErrorLine role="alert" aria-label="dependencies">
                                            <FormattedMessage
                                                id="admin.addpackage.dependencies.required"
                                                defaultMessage="Geef 1 of meer afhankelijkheden op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <Textbox
                                        {...register("dependencies")}
                                        id="dependencies"
                                        name="dependencies"
                                        placeholder={
                                            intl.formatMessage({
                                                id: "admin.addpackage.dependencies.placeholdder",
                                                defaultMessage: "Software die vooraf geinstalleerd dient te zijn",
                                            })
                                        }
                                    />

                                    <Label htmlFor="license_message">
                                        <FormattedMessage
                                            id="admin.addpackage.license"
                                            defaultMessage="Licentie"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.license_message && (
                                        <ErrorLine role="alert" aria-label="license">
                                             <FormattedMessage
                                                    id="admin.addpackage.license.required"
                                                    defaultMessage="Geef een licentie op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}
                                    <Select {...register("license_message")} id="license_message" defaultValue="none">
                                        <option value="1">
                                            {intl.formatMessage({
                                                    id: "packages.license.1",
                                                    defaultMessage: "Ja, wordt geïnstalleerd met dit pakket",
                                                })
                                            }
                                        </option>
                                        <option value="2">
                                            {intl.formatMessage({
                                                    id: "packages.license.2",
                                                    defaultMessage: "Ja, licentie dient gekoppeld te zijn aan je account",
                                                })
                                            }
                                        </option>
                                        <option value="3">
                                            {intl.formatMessage({
                                                    id: "packages.license.3",
                                                    defaultMessage: "Ja, licentie moet achteraf geactiveerd worden",
                                                })
                                            }
                                        </option>
                                        <option value="4">
                                            {intl.formatMessage({
                                                    id: "packages.license.4",
                                                    defaultMessage: "Nee, geen licentie nodig voor dit pakket",
                                                })
                                            }
                                        </option>
                                    </Select>

                                    <Label htmlFor="is_visible">
                                        <FormattedMessage
                                            id="admin.addpackage.visibility"
                                            defaultMessage="Beschikbaar"
                                        ></FormattedMessage>
                                    </Label>
                                    {errors.isVisible && (
                                        <ErrorLine role="alert" aria-label="visibility">
                                            <FormattedMessage
                                                id="admin.addpackage.visibility.required"
                                                defaultMessage="Geef een beschikbaarheid op"
                                            ></FormattedMessage>
                                        </ErrorLine>
                                    )}

                                    <Label
                                        htmlFor="isVisible-yes"
                                        style={{
                                            display: "inline-block",
                                            marginRight: "32px",
                                        }}
                                    >
                                        <input
                                            {...register("is_visible")}
                                            type="radio"
                                            name="is_visible"
                                            value="true"
                                            id="isVisible-yes"
                                        />
                                        &nbsp; <FormattedMessage
                                                    id="admin.addpackage.visibility.yes"
                                                    defaultMessage="Ja"
                                            ></FormattedMessage>
                                    </Label>
                                    <Label
                                        htmlFor="isVisible-no"
                                        style={{
                                            display: "inline-block",
                                            marginRight: "32px",
                                        }}
                                    >
                                        <input
                                            {...register("is_visible")}
                                            type="radio"
                                            name="is_visible"
                                            value="false"
                                            id="isVisible-no"
                                        />
                                        &nbsp; <FormattedMessage
                                                    id="admin.addpackage.visibility.no"
                                                    defaultMessage="Nee"
                                            ></FormattedMessage>
                                    </Label>

                                    <SubmitButton disabled={!image} text={
                                          intl.formatMessage({
                                            id: "admin.addpackage.submitbutton",
                                            defaultMessage: "Toevoegen",
                                        })
                                    } />
                                </FormWrapper>
                                <ImageWrapper>
                                    <ImageContainer>{imageFile && <img src={imageFile} alt="icon" />}</ImageContainer>
                                    <p style={{fontSize: "12px"}}>
                                        <FormattedMessage
                                                id="admin.addpackage.maxsize"
                                                defaultMessage="Maximale grootte"
                                        ></FormattedMessage>: 50Kb <br />
                                        <FormattedMessage
                                            id="admin.addpackage.bestdimensions"
                                            defaultMessage="Beste afmetingen"
                                        ></FormattedMessage>: 172px x 172px </p>
                                    <p>{image?.name}</p>
                                    <Label htmlFor="image_url" style={{ marginTop: "24px" }}>
                                        <FormattedMessage
                                                id="admin.addpackage.image"
                                                defaultMessage="Afbeelding"
                                        ></FormattedMessage>
                                    </Label>
                                    {imageError && (
                                        <ErrorLine role="alert" aria-label="image" data-testid="image-error">
                                            {imageError}
                                        </ErrorLine>
                                    )}

                                    <input
                                        id="image_url"
                                        type="file"
                                        data-testid="image-upload-button"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        {...register("image_url")}
                                        onChange={async (event) => {
                                            if (event?.currentTarget?.files) {
                                                await checkFileMimetype(
                                                    event.currentTarget.files[0],
                                                    setImage,
                                                    setimageError
                                                );
                                                generateFile(event);
                                            }
                                        }}
                                        
                                    />
                                </ImageWrapper>
                            </FormContent>
                        </form>
                    </ContentAreaWrapper>
                )}
            </AddPackageWrapper>
        </>
    );
};

export default injectIntl(AddPackage);
