import React, { HTMLAttributes, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IReduxApplicationState } from "../../models/redux/IReduxApplicationState";
import { fetchCustomerShorthand } from "../../store/slices/customers/customerActions";
import useMsalToken from "../../hooks/useMsalToken";

interface IHeaderProps extends HTMLAttributes<HTMLElement> {
    background: string;
    subKey: string
}

const Header = styled.header<IHeaderProps>`
    height: 212px;
    grid-column: 1 / 13;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: ${p => p.theme.headerBackgroundColor};
    ${props => props.background && `background-image: url(${props.background})` }
`;

export const HeaderComponent = ( { background, subKey, ...props } : IHeaderProps) => {
    const dispatch = useDispatch();
    const token: any = useMsalToken()

    useEffect(() => {
        if (subKey) {
            dispatch(fetchCustomerShorthand(subKey, token));
        }
    }, [subKey]);


    // const backgroundFile = `${process.env.REACT_APP_BLOB_STORAGE_CUSTOMER_IMAGES_URL}${customers?.customerName}.png`;
    
    return (
        // <Header data-testid="header" background={ backgroundFile } subKey='' { ...props } />
        <Header data-testid="header" background={background} subKey='' { ...props } />
    );
};
