import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {IReduxApplicationState} from "../../models/redux/IReduxApplicationState";
import {FormattedMessage, useIntl} from "react-intl";
import useMsalToken from "../../hooks/useMsalToken";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const BitlockerPin = ({isPinSet, hasLeapAccess}: any) => {
    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const intl = useIntl();

    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [confirmPinError, setConfirmPinError] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);

    const [typePin, setTypePin] = useState('password');
    const [typePinConfirm, setTypePinConfirm] = useState('password');

    const token: any = useMsalToken()

    const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPin = e.target.value;
        if (newPin.match(/^\d*$/)) {
            setPin(newPin);
            if (newPin.length >= 6 && newPin.length <= 20) {
                setPinError('');
            }
            else if (newPin.length >= 20) {
                setPinError(intl.formatMessage({
                    id: 'bitlocker.length.error.long',
                    defaultMessage: 'The PIN code can have no more than 20 digits.'
                }));
            }
            else {
                setPinError(intl.formatMessage({
                    id: 'bitlocker.length.error',
                    defaultMessage: 'The PIN code must at least have 6 digits.'
                }));
            }
        }
    };

    const handleConfirmPinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConfirmPin = e.target.value;
        if (newConfirmPin.match(/^\d*$/)) {
            setConfirmPin(newConfirmPin);
            if (newConfirmPin !== pin) {
                setConfirmPinError(intl.formatMessage({
                    id: 'bitlocker.create.error.match',
                    defaultMessage: 'The PINs do not match.'
                }));
            } else {
                setConfirmPinError('');
            }
        }

    };

    const handlePinVisibility = () => {
        if (typePin === 'password') {
            setTypePin('text')
        } else {
            setTypePin('password')
        }
    }

    const handleConfirmPinVisibility = () => {
        if (typePinConfirm === 'password') {
            setTypePinConfirm('text')
        } else {
            setTypePinConfirm('password')
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setPinError('');
        setConfirmPinError('')
        setIsLoading(true);

        setShowSuccessPopup(false);

        if (pin !== confirmPin) {
            let errorMsg = intl.formatMessage({
                id: 'bitlocker.create.error.match',
                defaultMessage: 'The PINs do not match.'
            })
            setConfirmPinError(errorMsg);
            setIsLoading(false);
            return;
        }

        if (pin.length < 6) {
            let errorMsgLength = intl.formatMessage({
                id: 'bitlocker.length.error',
                defaultMessage: 'PIN must be at least 6 characters long'
            })
            setPinError(errorMsgLength);
            return;
        }

        if (pin.length > 20) {
            let errorMsgLength = intl.formatMessage({
                id: 'bitlocker.length.error.long',
                defaultMessage: 'The PIN code can have no more than 20 digits.'
            })
            setPinError(errorMsgLength);
            return;
        }

        if (!subscriptionKey) {
            let errorMsg = intl.formatMessage({
                id: 'bitlocker.no.subscription.key',
                defaultMessage: 'The PINs do not match.'
            })
            setPinError(errorMsg);
            setIsLoading(false);
            return;
        }

        console.log(isPinSet)

        try {
            const baseUrl = `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement`;
            const endpoint = isPinSet ? 'update-pin' : 'set-pin';
            const url = `${baseUrl}/${endpoint}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': subscriptionKey,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({pin: pin}),
            });

            const data = await response.json();


            if (!response.ok || data.status === false) {
                throw new Error('Network response was not ok');
            }

            // Reset the pin input field on successful submission
            setPin('');


            setShowSuccessPopup(true);
            setIsSuccessful(true);
        } catch (error) {
            setPinError(intl.formatMessage({
                id: 'bitlocker.create.submit.error',
                defaultMessage: 'Failed to submit Bitlocker PIN. Please try again.'
            }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormContainer>
            {isSuccessful ? (
                <Container>
                    <Title style={{color: 'green'}}>
                        <FormattedMessage
                            id="bitlocker.create.submit.successfully"
                            defaultMessage="Your BitLocker PIN has successfully been created."
                        />
                    </Title>
                </Container>
            ) : (
                <StyledForm onSubmit={handleSubmit}>
                    <Container>
                        <Title>
                            <FormattedMessage id="bitlockerSetupTitle" defaultMessage="Set Your BitLocker PIN" />
                        </Title>
                        <Instructions>
                            <FormattedMessage id="bitlockerSetupInstructions" defaultMessage="To enhance security, your BitLocker PIN should meet the following criteria:" />
                        </Instructions>
                        <List>
                            <ListItem>
                                <FormattedMessage id="bitlockerConditionNumeric" defaultMessage="Only numeric digits are allowed." />
                            </ListItem>
                            <ListItem>
                                <FormattedMessage id="bitlockerConditionLength" defaultMessage="Must be at least 6 digits long." />
                            </ListItem>
                            <ListItem>
                                <FormattedMessage id="bitlockerConditionMaxLength" defaultMessage="Cannot exceed 20 digits." />
                            </ListItem>
                        </List>
                        <Important>
                            <FormattedMessage id="bitlockerimportantNote" defaultMessage="It is important to not lose your PIN code." />
                        </Important>
                    </Container>

                    <InputContainer>
                        <Input
                            type={typePin}
                            value={pin}
                            onChange={handlePinChange}
                            placeholder={intl.formatMessage({
                                id: 'bitlocker.placeholder.pin',
                                defaultMessage: 'Enter your PIN'
                            })}
                            required
                            minLength={6}
                            pattern="\d*"
                            autoFocus={true}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                        <span className="flex justify-around items-center" style={{marginLeft: "10px"}}
                              onClick={handlePinVisibility}>
                        {typePin === 'password' ? <VisibilityOffIcon style={{fontSize: 20}}/> : <VisibilityIcon style={{fontSize: 20}}/>}
                    </span>
                    </InputContainer>

                    <InputContainer>
                        <Input
                            type={typePinConfirm}
                            value={confirmPin}
                            onChange={handleConfirmPinChange}
                            placeholder={intl.formatMessage({
                                id: 'bitlocker.placeholder.confirm-pin',
                                defaultMessage: 'Confirm your PIN'
                            })}
                            required
                            minLength={6}
                            pattern="\d*"
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />

                        <span className="flex justify-around items-center" style={{marginLeft: "10px"}}
                              onClick={handleConfirmPinVisibility}>
                            {typePinConfirm === 'password' ? <VisibilityOffIcon style={{fontSize: 20}}/> : <VisibilityIcon style={{fontSize: 20}}/>}
                    </span>
                    </InputContainer>

                    <div style={{color: 'red'}}>{pinError}</div>
                    {confirmPinError && <div style={{color: 'red'}}>{confirmPinError}</div>}

                    {isLoading ? (
                        <Spinner/>
                    ) : showSuccessPopup && (
                        <h1 style={{color: 'green'}}>
                            <FormattedMessage
                                id="bitlocker.create.submit.successfully"
                                defaultMessage="Your Bitlocker PIN has successfully been created."
                            ></FormattedMessage>
                        </h1>
                    )}

                    <ButtonContainer>
                        <SubmitButton type="submit">
                            <FormattedMessage
                                id="bitlocker.create.submit.button"
                                defaultMessage="Your Bitlocker PIN has successfully been created."
                            ></FormattedMessage></SubmitButton>
                    </ButtonContainer>
                </StyledForm>
            )}
        </FormContainer>
    );
};

const SubmitButton = styled.button`
  padding: 5px 5px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  transition-duration: 0.4s;

  &:hover {
    background-color: white;
    color: black;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;

`;


const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  margin: 2rem;
  padding: 2rem;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Title = styled.h1`
  color: #333;
  font-size: 24px;
  margin-bottom: 1rem;
`;

const Instructions = styled.p`
  color: #666;
  font-size: 18px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  color: #444;
  font-size: 16px;
  margin-bottom: 0.5rem;
  padding-top: 10px;
  padding-left: 40px;

  &:before {
    content: '•';
    color: #007bff;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Important = styled.p`
  color: crimson;
  font-size: 16px;
  margin-top: 10px;
`;