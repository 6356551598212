import React, { FC } from 'react';
import styled from 'styled-components';
import { Header1 } from '../html/header/Header';

const OverLay = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0, .85);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    position: absolute;
    z-index: 10000000;
    top: 0;
    left: 0;
`;

export const ScreenOverlay: FC<any> = ({ children, ...props }) => {
  return (
    <OverLay>
        {children}
    </OverLay>
  )
}
