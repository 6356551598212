import { styled, Slider, Box } from '@mui/material'



const CustomSlider = styled(Slider)`
  
 .MuiSlider-markLabel {
  font-size: 1.2rem
},
`;

export const MinuteSlider = ({value, onChange}: any) => {

    const marks = [
        {
            value: 15,
            label: '15',
        },
        {
            value: 30,
            label: '30',
        },
        {
            value: 60,
            label: '60',
        },
    ];

    function valuetext(value: number) {
        return `${value}`;
    }

    function valueLabelFormat(value: number) {
        return marks.findIndex((mark) => mark.value === value) + 1;

    }

    const handleSliderChange = (event: any, newValue: any) => {
        onChange(newValue);
    };

    return (
        <Box sx={{ width: '100%', fontSize: '50px'}}>
            <CustomSlider
                aria-label="Restricted values"
                defaultValue={15}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                step={null}
                valueLabelDisplay="off"
                marks={marks}
                min={15}
                max={60}
                value={value}
                onChange={handleSliderChange}
            />
        </Box>
    )
}