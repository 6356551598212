export default {
    "navigation.category.text": "categorieën",
    "navigation.home.text": "Alle Applicaties",
    "navigation.patches.text": "Beveiligingsupdates",
    "navigation.category.multimedia": "Multimedia",
    "navigation.category.misc": "Overig",
    "navigation.category.productivityTools": "Productiviteitstools",
    "navigation.category.officesoftware": "Bedrijfssoftware",
    "navigation.category.internet": "Internet",
    "navigation.admin.panel": "Beheerportaal",

    "errormessages.general": "Er is iets fout gegaan, probeer het later opnieuw",
    "errormessages.deplicate":
        "De applicatie staat momenteel in de wachtrij om geinstalleerd te worden, een ogenblik geduld alstublieft.",

    "details.title.category": "Categorie",
    "details.title.license": "Licentie",
    "details.title.tags": "Tags",
    "details.title.requirements": "Benodigheden",
    "details.title.installationtime": "Installatietijd",
    "details.title.restartrequired": "Herstart nodig",
    "details.title.installtext": "Installeren",
    "details.header.otherinfo": "Overige informatie",
    "details.header.intsalling.message": "De applicatie is toegevoegd aan de wachtrij om geinstalleerd te worden. let op zorg ervoor dat de laptop aan blijft totdat de installatie is voltooid.",
    "details.title.installed": "Geïnstalleerd",
    "details.miniutes": " minuten",
    "details.installed": "Package is geinstalleerd",
    "details.title.toinstall": "installeren",
    "general.none": "Geen",

    "category.packages": "Er is momenteel geen software beschikbaar binnen deze categorie",

    "general.yes": "Ja",
    "general.no": "Nee",
    "general.remove": "Verwijderen",
    "general.isinstalled.text": "Geïnstalleerd",
    "general.install.text": "Installeren",
    "general.free": "Gratis",
    "general.requires.license": "Licentie vereist",

    "packages.nopackages-available": "Er zijn geen applicaties beschikbaar",
    "packages.installed.packages": "Geïnstalleerde software",

    "packages.license.1": "Ja, wordt geïnstalleerd met dit pakket",
    "packages.license.2": "Ja, licentie dient gekoppeld te zijn aan je account",
    "packages.license.3": "Ja, licentie moet achteraf geactiveerd worden",
    "packages.license.4": "Nee, geen licentie nodig voor dit pakket",

    "no.rights.header": "U bent helaas niet gemachtigd om de store te gebruiken",
    "no.rights.subheader": "Neem contact op met de servicedesk om de store te activeren",

    "error.header.text":
        "Er is een onverwachte fout opgetreden, probeer het nogmaals of neem contact op met uw beheerder",

    "uninstalled-succesfully": " is gedeïnstalleerd",

    "thirdparty.install.package": "Installeer package",
    "thirdparty.package.name": "Pakketnaam",
    "thirdparty.package.name.required": "Pakketnaam is vereist",
    "thirdparty.computer.name": "Computernaam",
    "thirdparty.computer.name.required": "Computernaam is vereist",
    "thirdparty.customer.shorthand": "Afkorting klantnaam",
    "thirdparty.customer.shorthand.required": "Afkorting klantnaam is vereist",
    "thirdparty.show.notification": "Toon toast notificatie na installatie",
    "thirdparty.package.install.1": "Het pakket ",
    "thirdparty.package.install.2": " wordt geïnstalleerd op  ",
    "thirdparty.package.install..confirmation.1": "Weet u zeker dat u ",
    "thirdparty.package.install..confirmation.2": " op computer(s) ",
    "thirdparty.package.install..confirmation.3": " wilt installeren?",

    "admin.home.header": "Admin beheerpagina",

    "admin.addpackage.displayname": "Naam",
    "admin.addpackage.displayname.required": "Geef een weergave naam op",
    "admin.addpackage.displayname.placeholdder": "De naam die de klant ziet van het pakket",
    
    "admin.addpackage.category": "Categorie",
    "admin.addpackage.category.required": "Selecteer een categorie",
    
    "admin.addpackage.customershorthand": "Afkorting klant",
    "admin.addpackage.customershorthand.required": "Geef een afkorting op van de klant",

    "admin.addpackage.restart": "Herstart nodig",
    "admin.addpackage.restart.required": "Geef aan of een herstart nodig is",

    "admin.addpackage.installationtime": "Installatietijd",
    "admin.addpackage.installationtime.required": "Geef de installatietijd op als nummer",
    "admin.addpackage.installationtime.placeholdder": "Installatietijd in minuten",

    "admin.addpackage.weight": "Orderning",
    "admin.addpackage.weight.required": "Geef de positie op",
    "admin.addpackage.weight.placeholdder": "Positie van het pakket, van hoog naar laag",

    "admin.addpackage.tags": "Labels",
    "admin.addpackage.tags.required": "Geef een label op",
    "admin.addpackage.tags.placeholdder": "Labels om de pakketen te ordenen",

    "admin.addpackage.summary": "Korte omschrijving",
    "admin.addpackage.summary.required": "Geef een korte omschrijving op",
    "admin.addpackage.summary.placeholdder": "Een korte omschrijving van het pakket",

    "admin.addpackage.description": "Volledige beschrijving",
    "admin.addpackage.description.required": "Geef een volledige omschrijving op",
    "admin.addpackage.description.placeholdder": "Een volledige omschrijving van het pakket",

    "admin.addpackage.packagename": "Pakketnaam",
    "admin.addpackage.packagename.required": "Geef een pakketnaam op",
    "admin.addpackage.packagename.placeholdder": "Chocolatey pakketnaam",

    "admin.addpackage.dependencies": "Afhankelijkheden van software",
    "admin.addpackage.dependencies.required": "Geef 1 of meer afhankelijkheden op",
    "admin.addpackage.dependencies.placeholdder": "Software die vooraf geinstalleerd dient te zijn",

    "admin.addpackage.license": "Licentie",
    "admin.addpackage.license.required": "Geef een licentie op",

    "admin.addpackage.visibility": "Beschikbaar",
    "admin.addpackage.visibility.required": "Geef een beschikbaarheid op",
    "admin.addpackage.visibility.yes": "Ja",
    "admin.addpackage.visibility.no": "Nee",

    "admin.addpackage.image": "Afbeelding",
    "admin.addpackage.maxsize": "Maximale grootte",
    "admin.addpackage.bestdimensions": "Beste afmetingen",

    "admin.addpackage.submitbutton": "Toevoegen",

    "admin.addpackage.add.toast": "Het pakket is toegevoegd.",
    "admin.addpackage.add.toast.error": "Er is iets fout gegaan. Probeer het later nogmaals.",
    "admin.addpackage.back.button": "Terug naar admin overizcht",

    "admin.add.package.header": "Pakket toevoegen",

    "package.categories.package.installed": "Het pakket is geinstalleerd",
    "package.categories.package.added": "Het pakket is toegevoegd",
    "package.categories.link.add.package": "Pakket toevoegen",
    "package.categories.link.intsall.package": "Pakket installeren",
    "package.categories.customer": "Klant",
    "package.categories.link.no.packages": "Er zijn geen pakketten aanwezig.",

    "overlay.package.install.header.1": "Het pakket ", 
    "overlay.package.install.header.2": " wordt geïnstalleerd", 
    "overlay.package.install.paragraph": "Dit kan enkele momenten duren afhankelijk van de grootte van het pakket.", 
    "overlay.package.uninstall.header": " wordt gedeïnstalleerd", 
    "overlay.package.uninstall.paragraph": "Dit kan enkele momenten duren afhankelijk van de grootte van het pakket.",

    "navigation.uninstall.text": "Applicaties deïnstalleren",

    "navigation.page.appstore": "appwinkel",
    "navigation.page.bitlocker": "bitlocker",

    "modal.info": "Update uw pc om toegang te krijgen tot de nieuwste websitefuncties. Probeer het over 15 minuten opnieuw. Bedankt!",
    "modal.button": "vernieuwen",

    "bitlocker.forgot.main.text": "Er is al een pincode ingesteld. Als u uw pincode wilt wijzigen, kunt u deze hier wijzigen",
    "bitlocker.forgot.button": "Pincode wijzigen",
    "bitlocker.forgot.successfully": "Pincode is succesvol gewijzigd.",
    "bitlocker.forgot.submit.button": "indienen",
    "bitlocker.forget.current.pin": "Voer uw huidige pincode in",
    "bitlocker.forget.new.pin": "Voer uw nieuwe pincode in",
    "bitlocker.forget.submit.fail": "Kan de Bitlocker-pincode niet indienen. Probeer het opnieuw.",
    "bitlocker.forgot.main.text.2": "De pincode mag uitsluited uit cijfers bestaan",


    "bitlocker.create.main.text": "Stel uw Bitlocker-pincode in. De pincode mag uitsluitend uit cijfers bestaan",
    "bitlocker.create.submit.successfully": "Pincode is succesvol aangemaakt.",
    "bitlocker.create.submit.button": "Indienen",
    "bitlocker.create.submit.error": "Kan de Bitlocker-pincode niet indienen. Probeer het opnieuw.",
    "bitlocker.placeholder.pin": "Voer uw pincode in",
    "bitlocker.placeholder.confirm-pin": "Bevestig uw pincode",
    "bitlocker.create.error.match": "De pincodes komen niet overeen",

    "bitlocker.length.error": "De pincode moet minimaal 6 cijfers bevatten.",
    "bitlocker.length.error.long": "De pincode mag niet meer dan 20 cijfers bevatten.",
    "bitlocker.no.subscription.key": "subscription is niet beschikbaar. Kan pincode niet indienen.",

    "navigation.support.request-admin": "lokaal beheer",

    "request-admin.form.main": "vraag tijdelijk local admin aan",
    "request-admin.form.label.name": "Naam:",
    "request-admin.form.label.email": "E-mail:",
    "request-admin.form.label.requestForOthers": "Beheerrechten aanvragen voor iemand anders",
    "request-admin.form.label.othersName": "Naam van de ander:",
    "request-admin.form.label.othersComputerName": "Computer naam van de ander:",
    "request-admin.form.label.duration": "Duur (in minuten):",
    "request-admin.form.label.reason": "Reden voor beheertoegang:",
    "request-admin.form.button.submit": "Toegang aanvragen",
    'request-admin.duration.length.error': 'The duration has to be greater than 0',

    "request.form.submit.success": "Verzoek is verzonden",
    "request.form.submit.error": "Fout bij het indienen van de aanvraag",

    "adminAccount.created": "Lokaal beheerdersaccount aangemaakt",
    "adminAccount.username": "Gebruikersnaam:",
    "adminAccount.password": "Wachtwoord:",
    "adminAccount.note": "Belangrijk: Dit wachtwoord wordt slechts één keer getoond. Zorg ervoor dat u het nu kopieert. Vergeet ook niet om '.\\' voor de gebruikersnaam te plaatsen bij gebruik.",

    "bitlockerSetupTitle": "Stel uw BitLocker-pincode in",
    "bitlockerSetupInstructions": "Om de beveiliging te verbeteren, moet uw BitLocker-pincode aan de volgende criteria voldoen:",
    "bitlockerConditionNumeric": "Alleen numerieke cijfers zijn toegestaan.",
    "bitlockerConditionLength": "Moet minimaal 6 cijfers lang zijn.",
    "bitlockerConditionMaxLength": "Mag niet langer zijn dan 20 cijfers.",
    "bitlockerimportantNote": "Het is belangrijk dat u uw pincode niet kwijtraakt.",

    "request.form.submit.unauthorized": "U heeft niet de rechten om dit verzoek in te dienen." ,

};
