export default {
    "navigation.category.text": "Kategorien",
    "navigation.home.text": "Startseite",
    "navigation.patches.text": "Patches",
    "navigation.category.multimedia": "Multimedia",
    "navigation.category.misc": "Sonstiges",
    "navigation.category.productivityTools": "Produktivitätstools",
    "navigation.category.officesoftware": "Bürosoftware",
    "navigation.category.internet": "Internet",
    "navigation.admin.panel": "AdminPanel",
    "navigation.uninstall.text": "Anwendungen deinstallieren",

    "errormessages.general": "Etwas ist schief gelaufen, bitte versuchen Sie es später erneut",
    "errormessages.deplicate": "Die Anwendung befindet sich derzeit in der Warteschlange, bitte warten Sie einen Moment",

    "details.title.category": "Kategorie",
    "details.title.license": "Lizenz",
    "details.title.tags": "Tags",
    "details.title.requirements": "Anforderungen",
    "details.title.installationtime": "Installationszeit",
    "details.title.restartrequired": "Neustart erforderlich",
    "details.title.installtext": "Installieren",
    "details.header.otherinfo": "Informationen",
    "details.header.intsalling.message": "Die Anwendung wurde zur Installationswarteschlange hinzugefügt. Bitte beachten Sie, dass der Computer/Laptop eingeschaltet und aktiv sein muss, bis die Installation abgeschlossen ist",
    "details.title.installed": "Eingerichtet",
    "details.installed": "Eingerichtet",
    "details.title.toinstall": "Installiert",
    "details.miniutes": " Minuten",

    "category.packages": "Derzeit ist keine Software für diese Kategorie verfügbar",

    "general.yes": "Ja",
    "general.no": "Nein",
    "general.remove": "Entfernen",
    "general.none": "Keine",
    "general.isinstalled.text": "Installiert",
    "general.install.text": "Installieren",
    "general.free": "Kostenlos",
    "general.requires.license": "Lizenz erforderlich",

    "packages.nopackages-available": "Entschuldigung, es sind keine Pakete verfügbar",
    "packages.installed.packages": "Installierte Pakete",
    "packages.license.1": "Ja, wird bei der Installation inbegriffen sein",
    "packages.license.2": "Ja, Lizenz muss mit Ihrem Konto verknüpft werden",
    "packages.license.3": "Ja, Lizenz muss nach der Installation aktiviert werden",
    "packages.license.4": "Nein, es ist keine Lizenz erforderlich",

    "no.rights.header": "Sie haben nicht ausreichend Rechte, um den App Store zu nutzen",
    "no.rights.subheader": "Bitte kontaktieren Sie Ihre Serviceabteilung, um den Store zu aktivieren",

    "error.header.text":
        "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut, oder wenden Sie sich an Ihren Administrator, wenn das Problem weiterhin besteht",
    "uninstalled-succesfully": " Das Paket wurde erfolgreich deinstalliert",

    "thirdparty.install.package": "Paket installieren",
    "thirdparty.package.name": "Paketname",
    "thirdparty.package.name.required": "Paketname ist erforderlich",
    "thirdparty.computer.name": "Computername",
    "thirdparty.computer.name.required": "Computername ist erforderlich",
    "thirdparty.customer.shorthand": "Kundenkürzel",
    "thirdparty.customer.shorthand.required": "Kundenkürzel ist erforderlich",
    "thirdparty.show.notification": "Toast-Benachrichtigung nach der Installation anzeigen",
    "thirdparty.package.install.1": "Das Paket ",
    "thirdparty.package.install.2": " wird installiert auf ",
    "thirdparty.package.install..confirmation.1": "Sind Sie sicher, dass Sie das Paket installieren möchten ",
    "thirdparty.package.install..confirmation.2": " auf Computer(n) ",
    "thirdparty.package.install..confirmation.3": "",

    "admin.home.header": "Admin-Kontrollpanel",

    "admin.addpackage.displayname": "Anzeigename",
    "admin.addpackage.displayname.required": "Der Anzeigename ist erforderlich",
    "admin.addpackage.displayname.placeholdder": "Anzeigename des Pakets",

    "admin.addpackage.category": "Kategorie",
    "admin.addpackage.category.required": "Wählen Sie eine Kategorie aus",

    "admin.addpackage.customershorthand": "Kundenkürzel",
    "admin.addpackage.customershorthand.required": "Geben Sie ein Kundenkürzel ein",

    "admin.addpackage.restart": "Neustart ist erforderlich",
    "admin.addpackage.restart.required": "Wählen Sie aus, ob ein Neustart erforderlich ist",

    "admin.addpackage.installationtime": "Installationszeit",
    "admin.addpackage.installationtime.required": "Installation ist erforderlich und sollte eine Zahl sein",

    "admin.addpackage.installationtime.placeholdder": "Die Zeit, die das Paket zur Installation in Minuten benötigt",

    "admin.addpackage.weight": "Gewicht",
    "admin.addpackage.weight.required": "Das Gewicht ist erforderlich",
    "admin.addpackage.weight.placeholdder": "Paketreihenfolge: von hoch zu niedrig",

    "admin.addpackage.tags": "Tags",
    "admin.addpackage.tags.placeholdder": "Die Tags, um Pakete zu gruppieren",

    "admin.addpackage.summary": "Zusammenfassung",
    "admin.addpackage.summary.required": "Eine Zusammenfassung ist erforderlich",
    "admin.addpackage.summary.placeholdder": "Die Zusammenfassung des Pakets",

    "admin.addpackage.description": "Beschreibung",
    "admin.addpackage.description.required": " Eine Beschreibung ist erforderlich",
    "admin.addpackage.description.placeholdder": "Eine ausführliche Beschreibung des Pakets",

    "admin.addpackage.packagename": "Paketname",
    "admin.addpackage.packagename.required": " Ein Paketname ist erforderlich",
    "admin.addpackage.packagename.placeholdder": "Chocolatey Paketname",

    "admin.addpackage.dependencies": "Abhängigkeiten",
    "admin.addpackage.dependencies.required": "Geben Sie eine oder mehrere Abhängigkeiten ein",
    "admin.addpackage.dependencies.placeholdder": "Alle Abhängigkeiten, die vorinstalliert sein müssen",

    "admin.addpackage.license": "Lizenz",

    "admin.addpackage.visibility": "Sichtbar",
    "admin.addpackage.visibility.required": "Wählen Sie eine Sichtbarkeit",
    "admin.addpackage.visibility.yes": "Ja",
    "admin.addpackage.visibility.no": "Nein",

    "admin.addpackage.image": "Bild",
    "admin.addpackage.maxsize": "Maximale Größe",
    "admin.addpackage.bestdimensions": "Beste Abmessungen",

    "admin.addpackage.submitbutton": "Ein Paket hinzufügen",

    "admin.addpackage.add.toast": "Das Paket wurde hinzugefügt",
    "admin.addpackage.add.toast.error": "Etwas ist schief gelaufen, bitte versuchen Sie es später erneut",
    "admin.addpackage.back.button": "Zurück zur Übersicht",

    "admin.add.package.header": "Ein Paket hinzufügen",

    "package.categories.package.installed": "Das Paket wurde installiert",
    "package.categories.package.added": "Das Paket wurde zum Store hinzugefügt",
    "package.categories.link.add.package": " Paket hinzufügen",
    "package.categories.link.intsall.package": "Paket installieren",
    "package.categories.customer": "Kunde",
    "package.categories.link.no.packages": "Es gibt keine Pakete",

    "overlay.package.install.header.1": "Das Paket ",
    "overlay.package.install.header.2": " wird installiert",
    "overlay.package.install.paragraph": "Dies kann je nach Größe des Pakets mehrere Minuten dauern",
    "overlay.package.uninstall.header": " wird deinstalliert",
    "overlay.package.uninstall.paragraph": "Dies kann je nach Größe des Pakets mehrere Minuten dauern",

    "modal.info": "Aktualisieren Sie Ihren PC, um auf die neuesten Website-Funktionen zuzugreifen. Bitte versuchen Sie es in 15 Minuten erneut. Danke schön!",
    "modal.button": "Aktualisierung",

    "navigation.page.appstore": "app store",
    "navigation.page.bitlocker": "bitlocker",

    "bitlocker.forgot.main.text": "Es wurde bereits ein PIN-Code festgelegt. Wenn Sie Ihren PIN-Code ändern möchten, können Sie ihn hier ändern",
    "bitlocker.forgot.button": "PIN ändern",
    "bitlocker.forgot.successfully": "Ihre Bitlocker-PIN wurde erfolgreich geändert.",
    "bitlocker.forgot.submit.button": "einreichen",
    "bitlocker.forget.current.pin": "Geben Sie Ihre neue PIN ein",
    "bitlocker.forget.new.pin": "Geben Sie Ihre neue PIN ein",
    "bitlocker.forget.submit.fail": "Bitlocker-PIN konnte nicht übermittelt werden. Bitte versuche es erneut.",
    "bitlocker.forgot.main.text.2": "Der PIN-Code darf nur aus Zahlen bestehen",


    "bitlocker.create.main.text": "Richten Sie Ihre Bitlocker-PIN ein. Der PIN-Code darf nur Zahlen enthalten",
    "bitlocker.create.submit.successfully": "Ihre Bitlocker-PIN wurde erfolgreich erstellt.",
    "bitlocker.create.submit.button": "Einreichen",
    "bitlocker.create.submit.error": "Bitlocker-PIN konnte nicht übermittelt werden. Bitte versuche es erneut.",
    "bitlocker.placeholder.pin": "Geben Sie Ihren PIN-Code ein",
    "bitlocker.placeholder.confirm-pin": "Bestätigen Sie Ihre PIN",
    "bitlocker.create.error.match": "Die PINS stimmen nicht überein",

    "bitlocker.length.error": "Der PIN-Code muss mindestens 6 stellig sein.",
    "bitlocker.length.error.long": "Der PIN-Code darf maximal 20 Ziffern haben.",
    "bitlocker.no.subscription.key": "Der Abonnementschlüssel ist nicht verfügbar. PIN kann nicht übermittelt werden.",

    "navigation.support.request-admin": "lokaler Administrator",

    "request-admin.form.main": "Fordern Sie vorübergehend einen lokalen Administrator an",
    "request-admin.form.label.name": "Name:",
    "request-admin.form.label.email": "E-Mail:",
    "request-admin.form.label.requestForOthers": "Adminrechte für jemand anderen beantragen",
    "request-admin.form.label.othersName": "Name des Anderen:",
    "request-admin.form.label.othersComputerName": "Computername des Anderen:",
    "request-admin.form.label.duration": "Dauer (in Minuten):",
    "request-admin.form.label.reason": "Grund für den Adminzugriff:",
    "request-admin.form.button.submit": "Zugriff anfordern",
    'request-admin.duration.length.error': 'Die Dauer muss größer als 0 sein',

    "request.form.submit.success": "Anfrage wurde gesendet",
    "request.form.submit.error": "Fehler beim Absenden des Antrags",

    "adminAccount.created": "Lokales Admin-Konto erstellt",
    "adminAccount.username": "Benutzername:",
    "adminAccount.password": "Passwort:",
    "adminAccount.note": "Wichtig: Dieses Passwort wird nur einmal angezeigt. Bitte kopieren Sie es jetzt. Denken Sie daran, '.\\' vor dem Benutzernamen zu setzen, wenn Sie ihn verwenden.",

    "bitlockerSetupTitle": "Legen Sie Ihre BitLocker-PIN fest",
    "bitlockerSetupInstructions": "Um die Sicherheit zu erhöhen, muss Ihre BitLocker-PIN folgende Kriterien erfüllen:",
    "bitlockerConditionNumeric": "Es sind nur numerische Ziffern zulässig.",
    "bitlockerConditionLength": "Muss mindestens 6 Ziffern lang sein.",
    "bitlockerConditionMaxLength": "Darf nicht länger als 20 Ziffern sein.",
    "bitlockerimportantNote": "Es ist wichtig, dass Sie Ihren PIN-Code nicht verlieren.",

    "request.form.submit.unauthorized": "Sie sind nicht berechtigt, diese Anfrage zu stellen." ,

};