import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";
import {
    closeSuccessInstalledMessage,
    fetchComputerName,
    fetchSubscriptionKey,
    removePackageError,
} from "../../store/slices/packages/packagesActions";
import { IReduxApplicationState } from "../../models/redux/IReduxApplicationState";
import { ErrorMessagesEnum } from "../../models/errorsEnum";
import { Header1 } from "../../components/html/header/Header";
import { fetchIlionaCustomerPackages, fetchIlionaCustomers } from "../../store/slices/customers/customerActions";
import { Spinner } from "../../components/spinner/Spinner";
import CategoriesPackages from "../../components/categoryPackages/CategoriesPackages";
import { Alert } from "react-bootstrap";
import { IIlionaCustomers } from "../../models/IlionaCustomers";
import { Label } from "../../styles/shared/formStyles";
import { fetchIlionaCategories } from "../../store/slices/categories/categoryActions";
import { IlionaPackageByCategory } from "../../models/IilionaPackagesByCategory";
import { filterPackagesPerCategory } from "../../utils/orderPackagesByCategory";
import { checkWhetherIsIcoAdmin } from "../../utils/general";
import useMsalToken from "../../hooks/useMsalToken";

const MainContent = styled.div`
    display: flex;
    grid-row: 6 / 7;
    grid-column: 2 / 12;
    margin: 3.2rem 0;
    flex-direction: column;
`;

const AdminBar = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;

    .inline {
        display: inline-block;
    }

    .mr {
        margin-right: 8px;
    }
`;

const LinksWrapper = styled.ul`
    display: flex;
    width: 400px;
    justify-content: flex-end;

    li {
        display: inline;

        a {
            color: ${(p) => p.theme.primaryColor};
            text-decoration: none;
            margin-left: 24px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

const Select = styled.select`
    display: block;
    align-items: center;
    padding: 6px 12px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${(p) => p.theme.primaryTextColor};
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus-visible {
        outline: -webkit-focus-ring-color 1px;
        outline-color: ${(p) => p.theme.primaryColor};
        outline-style: auto;
        outline-width: 1px;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #999;
    }
`;

const AdminHome = ({ intl }: WrappedComponentProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const packages = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    const categories = useSelector((state: IReduxApplicationState) => state.categorySlice);
    const customers = useSelector((state: IReduxApplicationState) => state.customersSlice);
    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const computerName = useSelector((state: IReduxApplicationState) => state.packagesSlice.computerName);
    const [showInstallSuccess, setShowInstallSuccess] = useState<boolean>(false);

    const [categoriesWithPackages, setCategoriesWithPackages] = useState<IlionaPackageByCategory[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<string>("");

    const [showSpinner, setShowSpinner] = useState(true);
    const errorMessageComputername = useSelector(
        (state: IReduxApplicationState) => state.packagesSlice.computerNameError
    );

    const [storeVersion, setStoreVersion] = useState(1)

    const token: any = useMsalToken()

    const fetchStoreVersion = async () => {
        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            const response: Response = await fetch(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/store-version`,
                {
                    headers: requestHeaders,
                }
            )

            if(response.status === 200) {
                setStoreVersion(2)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchStoreVersion()
    }, []);

    const fetchSubscriptionKeyData = async () => {
        await dispatch(fetchSubscriptionKey(storeVersion, token));
    };

    useEffect(() => {
        fetchSubscriptionKeyData();
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchComputerName(storeVersion, token));
    }, [dispatch, subscriptionKey]);

    useEffect(() => {
        if (errorMessageComputername === ErrorMessagesEnum.noCSAClientFound) {
            return navigate("/appstore/notallowed", { replace: true });
        } else {
            setShowSpinner(false);
        }
    }, [errorMessageComputername, computerName]);

    useEffect(() => {
        if (subscriptionKey) {

            if (subscriptionKey) {
                checkWhetherIsIcoAdmin(packages?.subscriptionKey, navigate);
                dispatch(fetchIlionaCustomers(subscriptionKey, token));
            }

            dispatch(fetchIlionaCustomers(subscriptionKey, token));
            dispatch(fetchIlionaCategories(subscriptionKey, token));
            if (selectedCustomer) {
                dispatch(fetchIlionaCustomerPackages(subscriptionKey, selectedCustomer, token));
            }
        }
    }, [dispatch, subscriptionKey, selectedCustomer]);

    useEffect(() => {
        if (
            customers?.customerPackages &&
            customers?.customerPackages.length > 0 &&
            categories?.categories &&
            categories?.categories.length > 0
        ) {
            if (customers.customerPackages.length === 0) { 
                setCategoriesWithPackages([]);
            }
            setCategoriesWithPackages(filterPackagesPerCategory(categories?.categories, customers?.customerPackages));
        } else {
            setCategoriesWithPackages([]);
        }
    }, [customers?.customerPackages, categories?.categories]);

    if (customers?.customers.length > 0 && !selectedCustomer) {
        setSelectedCustomer(customers?.customers[0]?.customerShorthand);
    }

    let customerName = "";
    if (selectedCustomer) {
        const customer: IIlionaCustomers | undefined = customers.customers.find(
            (cu) => cu.customerShorthand === selectedCustomer
        );
        if (customer) customerName = customer?.customerName;
    }

    if (packages?.packageInstallSuccessful && !showInstallSuccess) {
        dispatch(removePackageError());
        setShowInstallSuccess(true);
    }


    return (
        <MainContent>
            <>
                {(showSpinner && <Spinner />) || (!packages.computerName && <Spinner />)}

                {!showSpinner && packages?.errorMessage && (
                    <Alert variant="danger" style={{ marginBottom: "16px" }}>
                        <FormattedMessage
                            id="errormessages.general"
                            defaultMessage="Er is iets fout gegaan, probeer het later opnieuw."
                        ></FormattedMessage>
                    </Alert>
                )}

                {!showSpinner && showInstallSuccess && !packages?.errorMessage && (
                    <Alert variant="success" className="mb-4" dismissible onClose={() => setShowInstallSuccess(false)}>
                        <FormattedMessage
                            id="details.header.intsalling.message"
                            defaultMessage="De applicatie is toegevoegd aan de wachtrij om geinstalleerd te worden. let op zorg ervoor dat de laptop aan blijft totdat de installatie is voltooid."
                        ></FormattedMessage>
                    </Alert>
                )}

                {packages?.packageAdded && !showSpinner && packages.computerName && (
                    <Alert
                        variant="success"
                        className="mb-4"
                        dismissible
                        onClose={() => dispatch(closeSuccessInstalledMessage())}
                    >
                         <FormattedMessage
                                id="package.categories.package.added"
                                defaultMessage="Het pakket is toegevoegd"
                        ></FormattedMessage>
                    </Alert>
                )}

                {!showSpinner && packages.computerName && (
                    <>
                        <Header1>
                            <FormattedMessage
                                id="admin.home.header"
                                defaultMessage="Admin beheerpagina"
                            ></FormattedMessage>
                        </Header1>
                        <AdminBar>
                            <div style={{ width: "300px" }}>
                                <Label htmlFor="customer" className="inline mr">
                                    {
                                        intl.formatMessage({
                                            id: "package.categories.customer",
                                            defaultMessage: "Klant",
                                        })
                                    }
                                </Label>
                                <Select
                                    className="inline"
                                    id="customer"
                                    style={{width: '200px'}}
                                    value={selectedCustomer}
                                    onChange={(e) => setSelectedCustomer(e.target.value)}
                                >
                                    {customers?.customers.length > 0 &&
                                        customers?.customers.map((customer: IIlionaCustomers) => {
                                            return (
                                                <option
                                                    key={customer?.customerShorthand}
                                                    value={customer?.customerShorthand}
                                                >
                                                    {customer?.customerName}
                                                </option>
                                            );
                                        })}
                                </Select>
                            </div>

                            <div>
                                <LinksWrapper>
                                    <li>
                                        <NavLink to="/appstore/admin/packages/add">
                                            <FormattedMessage
                                                id="package.categories.link.add.package"
                                                defaultMessage="Pakket toevoegen"
                                            ></FormattedMessage>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/appstore/admin/installpackage">
                                            <FormattedMessage
                                                id="package.categories.link.intsall.package"
                                                defaultMessage="Pakket installeren"
                                            ></FormattedMessage>
                                        </NavLink>
                                    </li>
                                </LinksWrapper>
                            </div>
                        </AdminBar>
                        <div style={{ marginTop: "32px" }}>
                            <CategoriesPackages
                                packagesByCategory={categoriesWithPackages}
                                categories={categories?.categories}
                                localPackages={packages?.locallyInstalledPackages}
                                showEmptySoftwareHeaders={false}
                                enableLinks={false}
                            ></CategoriesPackages>

                            {categoriesWithPackages.length === 0 && (
                                <>
                                    <Header1>Store {customerName}</Header1>
                                    <p>
                                        <FormattedMessage
                                            id="package.categories.link.no.packages"
                                            defaultMessage="Er zijn geen packages aanwezig."
                                        ></FormattedMessage>    
                                    </p>
                                </>
                            )}
                        </div>
                    </>
                )}
            </>
        </MainContent>
    );
};

export default injectIntl(AdminHome);
