import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {loginRequest} from "../config/authConfig";
import {Outlet} from "react-router-dom";
import { HeaderComponent as Header } from "../components/header/Header";
import { HeaderBar } from "../components/headerBar/HeaderBar";
import {useSelector} from "react-redux";
import {IReduxApplicationState} from "../models/redux/IReduxApplicationState";
import styled from "styled-components";
import {Spinner} from "../components/spinner/Spinner";


const Wrapper = styled.div`
  color: ${(p) => p.theme.primaryTextColor};
`;

export const RootLayout = ({ onLocaleChange, appInitialLoading }: any) => {
    useMsalAuthentication(InteractionType.Redirect, loginRequest)

    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const handleLocaleChange = (locale: string) => {
        onLocaleChange(locale);
    };


    return (
        <div className="root-layout">
            <AuthenticatedTemplate>
                <Wrapper>
                    <HeaderBar onLocaleChange={onLocaleChange}/>
                    <Header background="/assets/img/ilionx_banner.jpg" subKey={subscriptionKey}/>

                    {appInitialLoading && (
                        <div style={{display: "none"}}>
                            <Spinner/>
                        </div>
                    )}
                    <main>
                        <Outlet/>
                    </main>
                </Wrapper>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h4>
                    <div>
                        Please sign-in to see your profile information.
                    </div>
                </h4>
            </UnauthenticatedTemplate>
        </div>
    )
}