import {useSelector} from "react-redux";
import { IReduxApplicationState } from "../models/redux/IReduxApplicationState";
import React, { useEffect, useState } from "react";
import {Spinner} from "../components/spinner/Spinner";
import {BitlockerPin} from "../components/bitlocker/BitlockerPin";
import {ErrorMessagesEnum} from "../models/errorsEnum";
import ErrorFailedConnectingStoreAgent from "../components/errorComponent/ErrorFailedConnectingStoreAgent";
import useMsalToken from "../hooks/useMsalToken";

export const Bitlocker = () => {
    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const [isPin, setIsPin] = useState(null);
    const errorAgent: string = useSelector(
        (state: IReduxApplicationState) => state.customersSlice.errorMessage
    );

    const token: any = useMsalToken()

    const GetUserBitlockerPin = async () => {
        if (subscriptionKey && token != "" ) {
            try {
                const response = await fetch(`http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/get-pin`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': subscriptionKey,
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setIsPin(data.status);
            } catch (error) {
                console.log("Something went wrong, try again");
            }
        }
    }

    useEffect(() => {
        if (token != "") {
            GetUserBitlockerPin();
        }
    }, [subscriptionKey, token]);

    return (
        <div>
            {errorAgent === ErrorMessagesEnum.noStoreAgentConnection ? (
                <ErrorFailedConnectingStoreAgent errorMsg={errorAgent}/>
            ) : isPin === null ? (
                <Spinner/>
            ) : (
                <BitlockerPin isPinSet={isPin}/>
            )}
        </div>
    )

};