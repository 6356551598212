import { IIlionaLocalPackage } from "./../../../models/ilionaLocalPackage";
import { IIlionaPackages, IIlionaPackagesAbbreviated } from "../../../models/IIlionaPackage";

export enum IlionaPackagesTypes {
    FETCH_ILIONA_PACKAGES_STARTED = "@@FETCH_ILIONA_PACKAGES_STARTED",
    FETCH_ILIONA_PACKAGES_SUCCESS = "@@FETCH_ILIONA_PACKAGES_SUCCESS",
    FETCH_ILIONA_PACKAGES_FAILURE = "@@FETCH_ILIONA_PACKAGES_FAILURE",

    FETCH_ILIONA_PACKAGE_DETAIL_STARTED = "@@FETCH_ILIONA_PACKAGE_DETAIL_STARTED",
    FETCH_ILIONA_PACKAGE_DETAIL_SUCCESS = "@@FETCH_ILIONA_PACKAGE_DETAIL_SUCCESS",
    FETCH_ILIONA_PACKAGE_DETAIL_FAILURE = "@@FETCH_ILIONA_PACKAGE_DETAIL_FAILURE",

    FETCH_ILIONA_INSTALL_PACKAGE_FAILURE = "@@FETCH_ILIONA_INSTALL_PACKAGE_FAILURE",
    FETCH_ILIONA_INSTALL_PACKAGE_STARTED = "@@FETCH_ILIONA_INSTALL_PACKAGE_STARTED",
    FETCH_ILIONA_INSTALL_PACKAGE_SUCCESS = "@@FETCH_ILIONA_INSTALL_PACKAGE_SUCCESS",

    FETCH_ILIONA_COMPUTER_NAME_FAILURE = "@@FETCH_ILIONA_COMPUTER_NAME_FAILURE",
    FETCH_ILIONA_COMPUTER_NAME_STARTED = "@@FETCH_ILIONA_COMPUTER_NAME_STARTED",
    FETCH_ILIONA_COMPUTER_NAME_SUCCESS = "@@FETCH_ILIONA_COMPUTER_NAME_SUCCESS",

    FETCH_ILIONA_SUBSCRIPTION_KEY_FAILURE = "@@FETCH_ILIONA_SUBSCRIPTION_KEY_FAILURE",
    FETCH_ILIONA_SUBSCRIPTION_KEY_STARTED = "@@FETCH_ILIONA_SUBSCRIPTION_KEY_STARTED",
    FETCH_ILIONA_SUBSCRIPTION_KEY_SUCCESS = "@@FETCH_ILIONA_SUBSCRIPTION_KEY_SUCCESS",

    FETCH_ILIONA_LOCAL_PACKAGES_FAILURE = "@@FETCH_ILIONA_LOCAL_PACKAGES_FAILURE",
    FETCH_ILIONA_LOCAL_PACKAGES_STARTED = "@@FETCH_ILIONA_LOCAL_PACKAGES_STARTED",
    FETCH_ILIONA_LOCAL_PACKAGES_SUCCESS = "@@FETCH_ILIONA_LOCAL_PACKAGES_SUCCESS",

    ILIONA_ADD_PACKAGE_STARTED = "@@ILIONA_ADD_PACKAGE_STARTED",
    ILIONA_ADD_PACKAGE_FAILURE = "@@ILIONA_ADD_PACKAGE_FAILURE",
    ILIONA_ADD_PACKAGE_SUCCESS = "@@ILIONA_ADD_PACKAGE_SUCCESS",

    ILIONA_UNINSTALL_PACKAGE_STARTED = "@@ILIONA_UNINSTALL_PACKAGE_STARTED",
    ILIONA_UNINSTALL_PACKAGE_FAILURE = "@@ILIONA_UNINSTALL_PACKAGE_FAILURE",
    ILIONA_UNINSTALL_PACKAGE_SUCCESS = "@@ILIONA_UNINSTALL_PACKAGE_SUCCESS",

    CLOSE_TOAST_MESSAGE = "@@CLOSE_TOAST_MESSAGE",
    REMOVE_PACKAGE_ERROR = "@@REMOVE_PACKAGE_ERROR",
    REMOVE_UNINSTALL_ALERT = "@@REMOVE_UNINSTALL_ALERT",

    ILIONA_INSTALL_PACKAGE_SYNC_FAILURE = "@@ILIONA_INSTALL_PACKAGE_SYNC_FAILURE",
    ILIONA_INSTALL_PACKAGE_SYNC_STARTED = "@@ILIONA_INSTALL_PACKAGE_SYNC_STARTED",
    ILIONA_INSTALL_PACKAGE_SYNC_SUCCESS = "@@ILIONA_INSTALL_PACKAGE_SYNC_SUCCESS",
}

export type PackagesState = Readonly<{
    ilionaPackages: IIlionaPackagesAbbreviated[];
    selectedPackageDetail: IIlionaPackages[];
    errorMessage: string;
    isFetching: boolean;
    packageInstallSuccessful: boolean;
    packageInstallSuccessfulSync: boolean;
    packageInstallFailed: boolean;
    packageUninstallSuccessful: boolean;
    packageUninstallFailed: boolean;
    computerName: string;
    subscriptionKey: string;
    locallyInstalledPackages: IIlionaLocalPackage[];
    computerNameError: string;
    packageAdded: boolean;
    showOverlay: boolean
}>;
