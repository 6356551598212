import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {FormattedMessage, useIntl} from "react-intl";
import useMsalToken from "../../hooks/useMsalToken";
import {useDispatch, useSelector} from "react-redux";
import {IReduxApplicationState} from "../../models/redux/IReduxApplicationState";
import axios from "axios"
import {MinuteSlider} from "../MinuteSlider";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {fetchComputerName} from "../../store/slices/packages/packagesActions";

interface FormData {
    name?: string;
    email: string;
    reason: string;
    duration: number;
}

export const RequestAdmin = () => {
    const dispatch = useDispatch();

    const subscriptionKey = useSelector((state: IReduxApplicationState) => state.packagesSlice.subscriptionKey);
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [responseCredentials, setResponseCredentials] = useState("")
    const [sliderValue, setSliderValue] = useState(15);

    const {accounts} = useMsal();
    const userAccount = accounts[0] || null;
    const token: any = useMsalToken()
    const [isVerified, setIsVerified] = useState(false)
    const computerName = useSelector((state: IReduxApplicationState) => state.packagesSlice.computerName);
    const storeVersion = useSelector((state: IReduxApplicationState) => state.customersSlice.storeVersion);

    const getComputerName = async () => {
        dispatch(fetchComputerName(storeVersion, token));
    };

    useEffect(() => {
        if (computerName === "") {
            getComputerName();
        }
    }, [subscriptionKey]);

    const [requestDetails, setRequestDetails] = useState({
        name: userAccount ? userAccount.name : '',
        email: userAccount ? userAccount.username : '',
        duration: 15,
        reason: ''
    });

    const handleChange = (e: any) => {
        const {name, value, type} = e.target;
        setRequestDetails(prevDetails => ({
            ...prevDetails,
            [name]: type === 'select-one' ? parseInt(value) : value
        }));
    };

    const handleSliderChange = (newValue: any) => {
        setSliderValue(newValue);
        setRequestDetails(prevDetails => ({
            ...prevDetails,
            duration: newValue
        }));
    };

    function fetchAuthenticatedData() {
        fetch(`${process.env.REACT_APP_API_URL}/leap_access`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': subscriptionKey,
                'Authorization': `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    return
                }
                return response.json()
            } )
            .then((data) => {
                if (data.data === false) {
                    return
                }
                setIsVerified(data);
            })
            .catch((e) => {
                console.error(e);
                setIsVerified(false);
            });
    }

    useEffect(() => {
        if (userAccount) {
            setRequestDetails(prevDetails => ({
                ...prevDetails,
                name: userAccount.name,
                email: userAccount.username
            }));

        }

        if (token) {
            fetchAuthenticatedData()
        }
    }, [userAccount, token]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!isVerified) {
            setSubmitError(intl.formatMessage({
                id: 'request.form.submit.unauthorized',
                defaultMessage: 'You do not have the rights to make this request.',
            }));
            return;
        }

        setShowSuccessPopup(false);
        setIsLoading(true);
        setSubmitError("");
        setResponseCredentials("");

        let formData = {
            name: requestDetails.name,
            email: requestDetails.email,
            reason: requestDetails.reason,
            duration: requestDetails.duration,
        };

        try {
            const response = await axios.post(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/leap`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': subscriptionKey,
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setResponseCredentials(response.data);
                setShowSuccessPopup(true);
                setIsSuccessful(true);

                const userKey = Object.keys(response.data)[0];

                try {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/leap_report`,
                        {
                            reason: requestDetails.reason,
                            duration: requestDetails.duration,
                            user: userKey,
                            computer: computerName

                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': subscriptionKey,
                                'Authorization': `Bearer ${token}`,
                            },
                        }
                    );
                } catch(leap_response_error) {
                    console.log(leap_response_error);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    setSubmitError(intl.formatMessage({
                        id: 'request.form.submit.unauthorized',
                        defaultMessage: 'You do not have the right to make this request.',
                    }));
                } else {
                    setSubmitError(intl.formatMessage({
                        id: 'request.form.submit.error',
                        defaultMessage: 'Error submitting application.',
                    }));
                }
            } else {
                setSubmitError(intl.formatMessage({
                    id: 'request.form.submit.networkError',
                    defaultMessage: 'Network error. Please try again.',
                }));
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AdminRequestWrapper>
            <div style={{display: "flex", justifyContent: "center", paddingBottom: "20px", fontSize: "20px"}}>
                <FormattedMessage id="request-admin.form.main" defaultMessage="temporarily request local admin"/>
            </div>
            <FormWrapper>
                {isSuccessful && responseCredentials ? (
                    <AdminCredentialsDisplay>
                        <h2 style={{fontSize: "18px", fontWeight: "bold", marginBottom: "10px"}}>
                            <FormattedMessage id="adminAccount.created" defaultMessage="Local Admin Account Created"/>
                        </h2>
                        {Object.entries(responseCredentials).map(([username, password], index) => (
                            <div key={index}>
                                <p><strong style={{paddingRight: "10px"}}><FormattedMessage id="adminAccount.username"
                                                             defaultMessage="Username:"/></strong>.\{username} <button
                                    type="button"
                                    onClick={() => navigator.clipboard.writeText(`.\\${username}`)}
                                    title="Copy Username"
                                    style={{background: 'none', border: 'none', cursor: 'pointer'}}
                                >
                                    <ContentCopyIcon sx={{ fontSize: 20 }}/>
                                </button></p>



                                <p><strong style={{paddingRight: "10px"}}><FormattedMessage id="adminAccount.password"
                                                             defaultMessage="Password:"/></strong>{password}  <button
                                    type="button"
                                    onClick={() => navigator.clipboard.writeText(password)}
                                    title="Copy Password"
                                    style={{background: 'none', border: 'none', cursor: 'pointer'}}
                                >
                                    <ContentCopyIcon sx={{ fontSize: 20 }}/>
                                </button></p>


                            </div>
                        ))}
                        <p style={{color: '#ff0000', marginTop: '15px'}}>
                            <FormattedMessage id="adminAccount.note"
                                              defaultMessage="Please note that this password will only be displayed once. Make sure to copy it now."/>
                        </p>
                    </AdminCredentialsDisplay>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <InfoContainer>
                            <InfoField>
                                <InfoLabel>Name:</InfoLabel>
                                <InfoValue>{requestDetails.name}</InfoValue>
                            </InfoField>
                            <InfoField>
                                <InfoLabel>Email:</InfoLabel>
                                <InfoValue>{requestDetails.email}</InfoValue>
                            </InfoField>
                        </InfoContainer>

                        {/*<FormField>*/}
                        {/*    <Label htmlFor="name">*/}
                        {/*        <FormattedMessage id="request-admin.form.label.name" defaultMessage="Name:"/>*/}
                        {/*    </Label>*/}
                        {/*    <Input*/}
                        {/*        id="name"*/}
                        {/*        name="name"*/}
                        {/*        value={requestDetails.name}*/}
                        {/*        disabled*/}
                        {/*    />*/}
                        {/*</FormField>*/}
                        {/*<FormField>*/}
                        {/*    <Label htmlFor="email">*/}
                        {/*        <FormattedMessage id="request-admin.form.label.email" defaultMessage="Email:"/>*/}
                        {/*    </Label>*/}
                        {/*    <Input*/}
                        {/*        id="email"*/}
                        {/*        name="email"*/}
                        {/*        value={requestDetails.email}*/}
                        {/*        disabled*/}
                        {/*    />*/}
                        {/*</FormField>*/}

                        <FormField>
                            <Label htmlFor="duration">
                                <FormattedMessage id="request-admin.form.label.duration"
                                                  defaultMessage="Duration (minutes):"/>
                            </Label>
                            <MinuteSlider value={sliderValue} onChange={handleSliderChange}/>
                        </FormField>

                        <FormField>
                            <Label htmlFor="reason">
                                <FormattedMessage id="request-admin.form.label.reason"
                                                  defaultMessage="Reason for Admin Access:"/>
                            </Label>
                            <InputTextArea
                                id="reason"
                                name="reason"
                                value={requestDetails.reason}
                                onChange={handleChange}
                                disabled={isSuccessful}
                                required
                            />
                        </FormField>

                        <div style={{display: "flex", justifyContent: "center", paddingBottom: '10px'}}>
                            {isLoading ? (
                                <Spinner/>
                            ) : showSuccessPopup && (
                                <h1 style={{
                                    color: 'green',
                                    paddingBottom: '10px',
                                    fontSize: "15px",
                                    fontWeight: "bold"
                                }}>
                                    {
                                        Object.keys(responseCredentials).map((key: any, index: any) => (
                                            <div key={index}> Username: {key} <span style={{
                                                content: "\a",
                                                whiteSpace: "pre"
                                            }}>password: {responseCredentials[key]}</span></div>
                                        ))
                                    }
                                </h1>
                            )}
                        </div>

                        {!submitError ? (
                            <></>
                        ) : (
                            <h1 style={{color: 'red', paddingBottom: "10px"}}>{submitError}</h1>
                        )}

                        <Button type="submit" disabled={isSuccessful}>
                            <FormattedMessage id="request-admin.form.button.submit" defaultMessage="Request Access"/>
                        </Button>
                    </form>
                )}

            </FormWrapper>
        </AdminRequestWrapper>
    )
}

const AdminRequestWrapper = styled.div`
  color: black;
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-top: 20px;
  padding: 2rem;
  background: white;
`;

const FormWrapper = styled.div`
  background: #f3f3f3;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const InputTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #0056b3;
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AdminCredentialsDisplay = styled.div`
  background-color: #e8f4e9;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: left;
`;

const InfoContainer = styled.div`
  font-family: Arial, sans-serif;
  max-width: 700px;
  margin: 20px auto;
`;

const InfoField = styled.div`
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 10px 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const InfoLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const InfoValue = styled.span`
  color: #333;
  background-color: #e9ecef;
  border-radius: 10px;
  padding: 5px 15px;
  display: inline-block;
`;