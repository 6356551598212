import React, { useEffect, useState } from "react";
import { Spinner } from "../components/spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Header1, Header3 } from "../components/html/header/Header";
import { IReduxApplicationState } from "../models/redux/IReduxApplicationState";
import {
    closeSuccessInstalledMessage,
    fetchComputerName,
    fetchIlionaPackageDetails,
    fetchLocalPackages,
    InstallPackage,
    installPackageSync,
} from "../store/slices/packages/packagesActions";
import { screenSize } from "../themes/global";
import { Alert } from "react-bootstrap";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { translateRoutePaths } from "../i18n/CategoryTranslations";
import { checkPackageIsInstalled } from "../utils/general";
import { ErrorMessagesEnum } from "../models/errorsEnum";
import { ScreenOverlay } from "../components/screenOverlay/ScreenOverlay";
import useMsalToken from "../hooks/useMsalToken";

const ToastWrapper = styled.div`
    display: flex;
    grid-row: 5 / 6;
    grid-column: 1 / 13;
    flex-direction: column;
    margin-top: 32px;

    @media ${screenSize.tablet} {
        grid-column: 2 / 12;
    }

    @media ${screenSize.desktop} {
        grid-column: 4 / 10;
    }
`;

const DetailPageWrapper = styled.div`
    display: flex;
    grid-row: 6 / 7;
    grid-column: 1 / 13;
    flex-direction: column;
    background: white;

    @media ${screenSize.tablet} {
        grid-column: 2 / 12;
        margin: 3.2rem 0;
    }

    @media ${screenSize.desktop} {
        grid-column: 4 / 10;
        margin: 3.2rem 0;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    @media ${screenSize.tablet} {
        flex-direction: row;
        height: 230px;
    }
`;

const HeaderLeftSection = styled.div`
    width: 100%;
    justify-content: center;

    @media ${screenSize.tablet} {
        width: 204px;
        justify-content: initial;
    }
`;

const HeaderRightSection = styled.div`
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;

    @media ${screenSize.tablet} {
        padding: 16px 32px;
        width: calc(100% - 204px);
    }

    .installed-text {
        font-size: 1.4rem;
    }

    .license-text {
        font-size: 1.4rem;

        .license-required {
            color: red;
        }

        .license-not-required {
            color: green;
        }

        i {
            font-style: italic;
        }
    }
`;

const InstallButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: 32px 0;
    align-items: flex-end;

    @media ${screenSize.tablet} {
        justify-content: flex-end;
        margin: 0;
    }
`;

const InstallButton = styled.button`
    padding: 4px 64px;
    background-color: ${(p) => p.theme.primaryColor};
    color: white;
    outline: none;
    border: none;
    font-size: 14px;

    &:disabled {
        background: #ccc;
        color: #222;
    }
`;

const DescriptionArea = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    width: calc(100% - 32px);

    margin: 32px 0 32px 16px;
    border-bottom: 1px solid ${(p) => p.theme.borderNeutral};

    @media ${screenSize.tablet} {
        margin: 32px 0 32px 32px;
        width: calc(100% - 64px);
    }

    p {
        font-size: 14px;
    }
`;

const AdditionalInfoHeader = styled.div`
    color: ${(p) => p.theme.primaryColor};
    font-size: 20px;
    margin-left: 16px;

    @media ${screenSize.tablet} {
        margin-left: 32px;
    }
`;

const AdditionalDetailsWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    padding: 16px;
    width: 100%;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);

    @media ${screenSize.tablet} {
        padding-left: 32px;
        width: calc(100% - 16px);
    }

    .item {
        display: flex;
        width: 100%;
        flex-direction: column;
        font-size: 14px;

        i {
            font-style: italic;
        }
    }
`;

const PackageDetail = ({ intl }: WrappedComponentProps) => {
    let { rowkey } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const packageDetails = useSelector((state: IReduxApplicationState) => state.packagesSlice);
    const [showSpinner, setShowSpinner] = useState(true);
    const [isInstalled, setIsInstalled] = useState(false)
    const [csaVersion, setCsaVersion] = useState(1)
    const [storeVersion, setStoreVersion] = useState(1)
    let showError = false;

    const token: any = useMsalToken()

    function checkInstalled() {
        if (packageDetails?.locallyInstalledPackages) {
            setIsInstalled(checkPackageIsInstalled(
                packageDetails?.locallyInstalledPackages,
                packageDetails?.selectedPackageDetail[0]?.PackageName
            ));
        }
    }

    const fetchCsaVersion = async () => {
        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            const response: Response = await fetch(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/csa`,
                {
                    headers: requestHeaders,
                }
            )
            const result = await response.json();

            setCsaVersion(result)
        } catch (error) {
            console.log("old store agent")
        }
    }

    const fetchStoreVersion = async () => {
        try {
            const requestHeaders: any = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            const response: Response = await fetch(
                `http://localhost:${process.env.REACT_APP_STOREFRONT_PORT}/api/v2/agentmanagement/store-version`,
                {
                    headers: requestHeaders,
                }
            )

            if(response.status === 200) {
                setStoreVersion(2)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchCsaVersion()
        fetchStoreVersion()
    }, []);


    useEffect(() => {
        checkInstalled();
    }, [packageDetails?.packageInstallSuccessful, packageDetails?.packageInstallSuccessfulSync , packageDetails?.selectedPackageDetail[0]])
    

    useEffect(() => {
        if (packageDetails?.subscriptionKey !== "") {
            dispatch(fetchIlionaPackageDetails(rowkey ? rowkey : "", packageDetails?.subscriptionKey, token));
            dispatch(fetchComputerName(storeVersion, token));
            checkInstalled();
        }
    }, [dispatch, packageDetails?.subscriptionKey]);

    useEffect(() => {
        dispatch(fetchLocalPackages(packageDetails.computerName, packageDetails?.subscriptionKey, storeVersion, token));
        setShowSpinner(false);
    }, [packageDetails.computerName]);

    useEffect(() => {
        if (packageDetails.computerNameError === ErrorMessagesEnum.noCSAClientFound) {
            return navigate("/notallowed", { replace: true });
        }
    }, [packageDetails.computerNameError]);

    if (packageDetails?.errorMessage) {
        showError = true;
    }

    const errorText = intl.formatMessage({
        id: "errormessages.general",
        defaultMessage: "Er is iets fout gegaan, probeer het later opnieuw.",
    });

    let warningText = intl.formatMessage({
        id: "errormessages.deplicate",
        defaultMessage:
            "De applicatie staat momenteel in de wachtrij om geinstalleerd te worden, een ogenblik geduld alstublieft.",
    });

    let successText = intl.formatMessage({
        id: "details.installed",
        defaultMessage:
            "Package is geinstalleerd.",
    });

    const errorMessage = (
        <ToastWrapper>
            <Alert variant="danger">{errorText}</Alert>
        </ToastWrapper>
    );
    const warningMessage = (
        <ToastWrapper>
            <Alert variant="warning" aria-label="warning-toast">
                {warningText}
            </Alert>
        </ToastWrapper>
    );
    const installSyncMessage = (
        <ToastWrapper>
            <Alert variant="success" aria-label="success">
                {successText}
            </Alert>
        </ToastWrapper>
    );

    const succesfullyInstalled = (
        <ToastWrapper>
            <Alert
                onClose={() => dispatch(closeSuccessInstalledMessage())}
                aria-label="success-toast"
                dismissible
                variant="success"
            >
                <FormattedMessage
                    id="details.header.intsalling.message"
                    defaultMessage="De applicatie is toegevoegd aan de wachtrij om geinstalleerd te worden. let op zorg ervoor dat de laptop aan blijft totdat de installatie is voltooid."
                ></FormattedMessage>
            </Alert>
        </ToastWrapper>
    );

    let licenseElement = null;
    switch (Number(packageDetails?.selectedPackageDetail[0]?.LicenseMessage)) {
        case 1:
            licenseElement = (
                <FormattedMessage
                    id="packages.license.1"
                    defaultMessage="Ja, wordt geïnstalleerd met dit pakket"
                ></FormattedMessage>
            );
            break;
        case 2:
            licenseElement = (
            <FormattedMessage
                id="packages.license.2"
                defaultMessage="Ja, licentie dient gekoppeld te zijn aan je account"
            ></FormattedMessage>)
            break;
        case 3:
            licenseElement = (
            <FormattedMessage
                id="packages.license.3"
                defaultMessage="Ja, licentie moet achteraf geactiveerd worden"
            ></FormattedMessage>)
            break;
        case 4:
            licenseElement = (
            <FormattedMessage
                id="packages.license.4"
                defaultMessage="No, there is no license required"
            ></FormattedMessage>)
            break;

        default:
            licenseElement = (
            <FormattedMessage
            id="packages.license.4"
            defaultMessage="No, there is no license required"
        ></FormattedMessage>)
            break;
    }

    const handleInstall = async (packageName: string, displayName: string) => {

        if(csaVersion === 2) {
            dispatch(
                installPackageSync(packageDetails?.selectedPackageDetail[0]?.PackageName, storeVersion, token)
            )
        } else {
            if (packageDetails?.selectedPackageDetail[0]?.InstallationTime > 2) {
                dispatch(
                    InstallPackage(
                        displayName,
                        packageName,
                        packageDetails?.computerName,
                        "",
                        false,
                        packageDetails?.subscriptionKey,
                        "install-package",
                        token,
                    )
                );
            } else {
                dispatch(
                    installPackageSync(packageDetails?.selectedPackageDetail[0]?.PackageName, storeVersion, token)
                )

            }
        }

    };
 

    return (
        <>
            {showError && packageDetails?.errorMessage !== ErrorMessagesEnum.duplicatePackage && errorMessage}
            {showError && packageDetails?.errorMessage === ErrorMessagesEnum.duplicatePackage && warningMessage}
            {packageDetails?.packageInstallSuccessfulSync && installSyncMessage}

            {packageDetails.showOverlay && (
                <ScreenOverlay>
                    <Header1>
                        {packageDetails?.selectedPackageDetail[0]?.DisplayName}

                        <FormattedMessage
                            id="overlay.package.install.header.2"
                            defaultMessage=" wordt geïnstalleerd"
                        ></FormattedMessage>
                    </Header1>
                    <p style={{color: '#BDBDBD'}}>
                        <FormattedMessage
                            id="overlay.package.install.paragraph"
                            defaultMessage="Dit kan enkele momenten duren afhankelijk van de grootte van het pakket."
                        ></FormattedMessage>
                    </p>
                    <Spinner style={{height: '140px'}} />
                </ScreenOverlay>
            )}



            {packageDetails?.packageInstallSuccessful && !packageDetails?.errorMessage && succesfullyInstalled}
            <DetailPageWrapper>
                {(showSpinner || packageDetails?.locallyInstalledPackages?.length === 0) && <Spinner />}

                {!showSpinner && packageDetails?.locallyInstalledPackages?.length > 0 && (
                    <section data-testid="body">
                        <HeaderWrapper>
                            <HeaderLeftSection>
                                <div
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_BLOB_STORAGE_URL}${packageDetails?.selectedPackageDetail[0]?.ImageUrl})`,
                                        maxWidth: "204px",
                                        height: "230px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        padding: "8px",
                                    }}
                                ></div>
                            </HeaderLeftSection>

                            <HeaderRightSection>
                                <Header1>{packageDetails?.selectedPackageDetail[0]?.DisplayName}</Header1>
                                <div className="license-text"> 
                                    <FormattedMessage
                                        id="details.title.category"
                                        defaultMessage="Categorie"
                                    ></FormattedMessage>
                                    :&nbsp;
                                     <i>
                                        {translateRoutePaths(packageDetails?.selectedPackageDetail[0]?.Category, intl)}
                                    </i>
                                </div>
                                <div className="license-text"> 
                                    <FormattedMessage
                                        id="details.title.license"
                                        defaultMessage="Licentie"
                                    ></FormattedMessage>
                                    :&nbsp;<i>{licenseElement}</i>
                                </div>
                                <div className="installed-text"> 
                                    <FormattedMessage
                                        id="details.title.installed"
                                        defaultMessage="geïnstalleerd"
                                    ></FormattedMessage>
                                    :&nbsp;
                                     <i data-testid="isInstalled" style={{fontStyle: 'italic'}}>
                                        {isInstalled ? (
                                            <FormattedMessage id="general.yes" defaultMessage="ja"></FormattedMessage>
                                        ) : (
                                            <FormattedMessage id="general.no" defaultMessage="nee"></FormattedMessage>
                                        )}
                                    </i>
                                </div>

                                <InstallButtonWrapper>
                                    <InstallButton
                                        disabled={packageDetails?.isFetching || isInstalled}
                                        onClick={() =>
                                            handleInstall(
                                                packageDetails?.selectedPackageDetail[0]?.PackageName,
                                                packageDetails?.selectedPackageDetail[0]?.DisplayName
                                            )
                                        }
                                    >
                                        {isInstalled ? <FormattedMessage id="details.title.installed" defaultMessage="Geïnstalleerd"></FormattedMessage> : <FormattedMessage id="details.title.toinstall" defaultMessage="installeren"></FormattedMessage>}
                                    </InstallButton>
                                </InstallButtonWrapper>
                            </HeaderRightSection>
                        </HeaderWrapper>

                        <DescriptionArea>
                            <Header3>{packageDetails?.selectedPackageDetail[0]?.DisplayName}</Header3>
                            <p>{packageDetails?.selectedPackageDetail[0]?.Description}</p>
                        </DescriptionArea>

                        <AdditionalInfoHeader>
                            <FormattedMessage
                                id="details.header.otherinfo"
                                defaultMessage="Overige informatie"
                            ></FormattedMessage>
                        </AdditionalInfoHeader>
                        <AdditionalDetailsWrapper>
                            <div className="item">
                                <p>
                                    <FormattedMessage id="details.title.tags" defaultMessage="Tags"></FormattedMessage>
                                </p>
                                <p>
                                    <i>{packageDetails?.selectedPackageDetail[0]?.Tags  || <FormattedMessage id="general.none" defaultMessage="Geen"></FormattedMessage>}</i>
                                </p>
                            </div>

                            <div className="item">
                                <p>
                                    <FormattedMessage
                                        id="details.title.requirements"
                                        defaultMessage="Benodigheden"
                                    ></FormattedMessage>
                                </p>
                                <p>
                                    <i>{packageDetails?.selectedPackageDetail[0]?.Dependencies || <FormattedMessage id="general.none" defaultMessage="Geen"></FormattedMessage>}</i>
                                </p>
                            </div>

                            <div className="item">
                                <p>
                                    <FormattedMessage
                                        id="details.title.installationtime"
                                        defaultMessage="Installatietijd"
                                    ></FormattedMessage>
                                </p>
                                <p>
                                    <i>{packageDetails?.selectedPackageDetail[0]?.InstallationTime}
                                    <FormattedMessage
                                        id="details.miniutes"
                                        defaultMessage="minuten"
                                    ></FormattedMessage>
                                    </i>
                                </p>
                            </div>

                            <div className="item">
                                <p>
                                    <FormattedMessage
                                        id="details.title.restartrequired"
                                        defaultMessage="Herstart nodig"
                                    ></FormattedMessage>
                                </p>
                                <p>
                                    <i>{packageDetails?.selectedPackageDetail[0]?.NeedToRestart ? <FormattedMessage id="general.yes" defaultMessage="Js"></FormattedMessage> : <FormattedMessage id="general.no" defaultMessage="Nee"></FormattedMessage>}</i>
                                </p>
                            </div>
                        </AdditionalDetailsWrapper>
                    </section>
                )}
            </DetailPageWrapper>
        </>
    );
};

export default injectIntl(PackageDetail);
