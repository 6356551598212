export default {
    "navigation.category.text": "Categories",
    "navigation.home.text": "Home",
    "navigation.patches.text": "Patches",
    "navigation.category.multimedia": "Multimedia",
    "navigation.category.misc": "Miscellaneous",
    "navigation.category.productivityTools": "Productivity tools",
    "navigation.category.officesoftware": "Office software",
    "navigation.category.internet": "Internet",
    "navigation.admin.panel": "Adminpanel",
    "navigation.uninstall.text": "uninstall applications",

    "errormessages.general": "Something has gone wrong, please try again later.",
    "errormessages.deplicate": "The application is currently queued, please wait a moment",

    "details.title.category": "Category",
    "details.title.license": "License",
    "details.title.tags": "Tags",
    "details.title.requirements": "Requirements",
    "details.title.installationtime": "Instalation time",
    "details.title.restartrequired": "Restart required",
    "details.title.installtext": "Install",
    "details.header.otherinfo": "Information",
    "details.header.intsalling.message": "The application is added to the installation queue, Please note that the computer/laptop needs to be powered on and active until the installation has been completed. ",
    "details.title.installed": "Installed",
    "details.miniutes": " minutes",
    "details.installed": "Package is installed",
    "details.title.toinstall": "To install",

    "category.packages": "Currently there is no software available for this category.",

    "general.yes": "Yes",
    "general.no": "No",
    "general.remove": "Remove",
    "general.none": "None",
    "general.isinstalled.text": "Installed",
    "general.install.text": "Install",
    "general.free": "Free",
    "general.requires.license": "License required",

    "packages.nopackages-available": "Sorry, there are no packages available",
    "packages.installed.packages": "Installed Packages",
    "packages.license.1": "Yes, will be included in install",
    "packages.license.2": "Yes, license must be linked to you account",
    "packages.license.3": "Yes, license must be activated after install",
    "packages.license.4": "No, there is no license required",

    "no.rights.header": "You do not have sufficient rights to use the App store",
    "no.rights.subheader": "Please contact your service department to activate the store",

    "error.header.text":
        "An unexpected error occured, please try again or persist your administrator if the problem persists",
    "uninstalled-succesfully": " has been uninstalled succesfully",

    "thirdparty.install.package": "Install package",
    "thirdparty.package.name": "Package name",
    "thirdparty.package.name.required": "Package name is required",
    "thirdparty.computer.name": "Computer name",
    "thirdparty.computer.name.required": "Computer name is required",
    "thirdparty.customer.shorthand": "Customer shorthand",
    "thirdparty.customer.shorthand.required": "Customer Shorthand is required",
    "thirdparty.show.notification": "Show toast notification after install",
    "thirdparty.package.install.1": "The package ",
    "thirdparty.package.install.2": " is being installed on  ",
    "thirdparty.package.install..confirmation.1": "Are you sure you want to install package ",
    "thirdparty.package.install..confirmation.2": " on computer(s) ",
    "thirdparty.package.install..confirmation.3": "",

    "admin.home.header": "Admin control panel",

    "admin.addpackage.displayname": "Display name",
    "admin.addpackage.displayname.required": "The display name is required",
    "admin.addpackage.displayname.placeholdder": "Display name of the package",
    
    "admin.addpackage.category": "Category",
    "admin.addpackage.category.required": "Select a category",
    
    "admin.addpackage.customershorthand": "Customer shorthand   ",
    "admin.addpackage.customershorthand.required": "Enter a customer shorthand",

    "admin.addpackage.restart": "Need to restart",
    "admin.addpackage.restart.required": "Select whether a restart is required",

    "admin.addpackage.installationtime": "Installation time",
    "admin.addpackage.installationtime.required": "Installation is required and should be a number",
    "admin.addpackage.installationtime.placeholdder": "The time the package needs to install in minutes",

    "admin.addpackage.weight": "Weight",
    "admin.addpackage.weight.required": "The weight is required",
    "admin.addpackage.weight.placeholdder": "Package order: high to low",

    "admin.addpackage.tags": "Tags",
    "admin.addpackage.tags.placeholdder": "The tags to group packages",

    "admin.addpackage.summary": "Summary",
    "admin.addpackage.summary.required": "The summary is required",
    "admin.addpackage.summary.placeholdder": "The summary of the package",

    "admin.addpackage.description": "Description",
    "admin.addpackage.description.required": "The description is required",
    "admin.addpackage.description.placeholdder": "A long description of the package",

    "admin.addpackage.packagename": "Package name",
    "admin.addpackage.packagename.required": "Package name is required",
    "admin.addpackage.packagename.placeholdder": "Chocolatey pakketnaam",

    "admin.addpackage.dependencies": "Dependencies",
    "admin.addpackage.dependencies.required": "Enter one or more dpeendencies",
    "admin.addpackage.dependencies.placeholdder": "Any dependencies that need to be pre-installed",

    "admin.addpackage.license": "Licence",

    "admin.addpackage.visibility": "Visible",
    "admin.addpackage.visibility.required": "Choose a visibility",
    "admin.addpackage.visibility.yes": "Yes",
    "admin.addpackage.visibility.no": "No",

    "admin.addpackage.image": "Image",
    "admin.addpackage.maxsize": "Max size",
    "admin.addpackage.bestdimensions": "Beste dimensions",

    "admin.addpackage.submitbutton": "Add package",

    "admin.addpackage.add.toast": "The package has been added.",
    "admin.addpackage.add.toast.error": "Somethign has gone wrong, please try again later.",
    "admin.addpackage.back.button": "Back to overview",

    "admin.add.package.header": "Add a package",


    "package.categories.package.installed": "The package has been installed.",
    "package.categories.package.added": "The package has been added to the store.",
    "package.categories.link.add.package": "Add package",
    "package.categories.link.intsall.package": "Install package",
    "package.categories.customer": "Customer",
    "package.categories.link.no.packages": "There are no packages.",

    "overlay.package.install.header.1": "The package ", 
    "overlay.package.install.header.2": " is being installed", 
    "overlay.package.install.paragraph": "This can take several minutes depending on the size of the package", 
    "overlay.package.uninstall.header": " is being uninstalled", 
    "overlay.package.uninstall.paragraph": "This can take several minutes depending on the size of the package",

    "modal.info": "Update your PC to access the latest website features. Please retry in 15 minutes. Thank you!",
    "modal.button": "refresh",

    "navigation.page.appstore": "app store",
    "navigation.page.bitlocker": "bitlocker",

    "bitlocker.forgot.main.text": "A PIN code has already been set. If you want to change your PIN code, you can change it here",
    "bitlocker.forgot.button": "Change PIN",
    "bitlocker.forgot.successfully": "Your Your Bitlocker PIN has been changed successfully.",
    "bitlocker.forgot.submit.button": "Submit",
    "bitlocker.forget.current.pin": "Enter your current PIN",
    "bitlocker.forget.new.pin": "Enter your PIN",
    "bitlocker.forget.submit.fail": "Failed to submit Bitlocker PIN. Please try again.",
    "bitlocker.forgot.main.text.2": "The PIN code may only consist of numbers",


    "bitlocker.create.main.text": "Set up your Bitlocker PIN. The PIN can only be comprised of digits",
    "bitlocker.create.button": "Forgot PIN",
    "bitlocker.create.submit.successfully": "Your Bitlocker PIN has successfully been created.",
    "bitlocker.create.submit.button": "Submit",
    "bitlocker.create.submit.error": "Failed to submit Bitlocker PIN. Please try again.",
    "bitlocker.placeholder.pin": "Enter your PIN",
    "bitlocker.placeholder.confirm-pin": "Confirm your PIN",
    "bitlocker.create.error.match": "The PINS do not match. ",

    "bitlocker.length.error": "The PIN code must at least have 6 digits.",
    "bitlocker.length.error.long": "The PIN code can have no more than 20 digits.",
    "bitlocker.no.subscription.key": "Subscription key is not available. Cannot submit PIN.",

    "navigation.support.request-admin": "local admin",

    "request-admin.form.main": "temporarily request local admin",
    "request-admin.form.label.name": "Name:",
    "request-admin.request-admin.form.label.email": "Email:",
    "request-admin.form.label.requestForOthers": "Request admin rights for someone else",
    "request-admin.form.label.othersName": "Other's Name:",
    "request-admin.form.label.othersComputerName": "Other's Computer Name:",
    "request-admin.form.label.duration": "Duration (minutes):",
    "request-admin.form.label.reason": "Reason for Admin Access:",
    "request-admin.form.button.submit": "Request Access",
    'request-admin.duration.length.error': 'The duration has to be greater than 0',

    "request.form.submit.success": "Request has been send",
    "request.form.submit.error": "Error submitting application",

    "adminAccount.created": "Local Admin Account Created",
    "adminAccount.username": "Username:",
    "adminAccount.password": "Password:",
    "adminAccount.note": "Important: This password will be shown just once. Please ensure you copy it now. Additionally, remember to prepend '.\\' to the username when using it.",

    "bitlockerSetupTitle": "Set Your BitLocker PIN",
    "bitlockerSetupInstructions": "To enhance security, your BitLocker PIN should meet the following criteria:",
    "bitlockerConditionNumeric": "Only numeric digits are allowed.",
    "bitlockerConditionLength": "Must be at least 6 digits long.",
    "bitlockerConditionMaxLength": "Cannot exceed 20 digits.",
    "bitlockerimportantNote": "It is important to not lose your PIN code.",

    "request.form.submit.unauthorized": "You do not have the rights to make this request." ,
};
